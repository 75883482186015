import { Typography, TextField, Button, Snackbar, Alert } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef } from "react";
import Panel from "./adminNavBar";
import { useState } from "react";
import ReactQuill, {Quill} from "react-quill";
import ImageResize from 'quill-image-resize-module-react'
import "react-quill/dist/quill.snow.css";
import { BiSend } from "react-icons/bi";
import { addDoc, collection } from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref as sRef,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import { db } from "../../firebase/firebase-config";
import ManageArticles from "./manageArticles";

Quill.register('modules/imageResize', ImageResize)

function Addblog() {
  const [open, setopen] = useState(false);
  const [file, setfile] = useState("");
  const [err, seterr] = useState("");
  const [value, setValue] = useState("");
  const content = useRef();
  //firebase references
  const storage = getStorage();
  const articlesref = collection(db, "Articles");
  content.current?.editor.root.setAttribute("spellcheck", "false");


  // send article   ______________________________________________________
  const sendArticle = async (e) => {
    e.preventDefault();
    e.target[23].innerHTML = "LOADING...";
    
    console.log([...content.current.value.matchAll(/<img\s+src="([^"]+)"[^>]*>/gm)]);

    const contentImages = [...content.current.value.matchAll(/<img\s+src="([^"]+)"[^>]*>/gm)]

    // console.log(contentImages);

    
    // ___ purify the image contents with new links after apploading them _____ 

    for (let i = 0; i < contentImages.length; i++){
      const storageRef = sRef(storage, "contentImages/" + new Date().toISOString());
      await uploadString(storageRef, contentImages[i][1], "data_url").then(async (snapshot) => {
      await getDownloadURL(snapshot.ref).then((url) => {

        content.current.value = content.current.value.replace(contentImages[i][1], url);
        console.log(content.current.value);
      })
    }
    )
    }

    // the start the process of sendint the blog to the database
    seterr("");
    e.target[23].innerHTML = "LOADING...";
    // console .log the target value of the send button fromt he form
    
    const file = e.target[2].files[0];
    const name = file.name;
    const meta = {
      contentType: file.type,
    };
    if (content.current.value.length > 10) {
      const storageRef = sRef(storage, "coverImages/" + name);

      try {

        uploadBytes(storageRef, file, meta).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((url) => {
            addDoc(articlesref, {
              title: e.target[0].value,
              description: e.target[1].value,
              cover: url,
              content: content.current.value,
              date:Date()
            }).then(() => {
              setopen(true);
              content.current.getEditor().setText("");
              e.target[23].innerText = "SEND";
              e.target.reset();
            });
          });
        });
      } catch (error) {
        setopen(true);
        console.log(error.message)
        seterr(error.message);
      }
    } else {
      setopen(true);
      seterr(
        "please enter the contents of your article in the designated fields"
      );
    }


  };


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return setopen(false);
    }
    setopen(false);
  };

  return (
    <div>
      <Panel />
      <Snackbar
        open={open}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        {err.length > 2 ? (
          <Alert
            onClose={handleClose}
            variant="filled"
            severity="error"
            sx={{ width: "100%" }}
          >
            {err}
          </Alert>
        ) : (
          <Alert
            onClose={handleClose}
            variant="filled"
            severity="success"
            sx={{ width: "100%" }}
          >
            Article successfully created!
          </Alert>
        )}
      </Snackbar>
      <Box
        sx={{
          display: "grid",
          padding: "10vh 0%",
          margin: "0% auto",
          marginLeft: { xs: "2.5em", md: "10em" },
        }}
      >
        <Box
          component="form"
          onSubmit={sendArticle}
          sx={{
            marginLeft: { xs: "1em", md: "10em" },
            marginRight: "3vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          
          <Typography variant="h4" sx={{ textAlign: "center", color: "#06c" }}>
            Add Article
          </Typography>
          <TextField
            type="text"
            variant="filled"
            sx={{ margin: "1% 0%" }}
            required
            name="title"
            label="enter title of the article"
            size="small"
            maxLength="55"
            minLength="20"
          />
          <TextField
            type="text"
            variant="filled"
            sx={{ margin: "0% 0%" }}
            required
            label="enter Article description"
            size="small"
            maxLength="200"
          />
          <input
            type="file"
            style={{ padding: "1%", fontSize: "large", fontWeight: "100" }}
            onChange={(e) => {
              setfile(URL.createObjectURL(e.target.files[0]));
            }}
            required
          />
          <img src={file} style={{ width: "100%" }} alt="noimage selected" />

          <Box>
            <ReactQuill
              theme="snow"
              ref={content}
              required
              modules={Addblog.modules}
              value={value}
              onChange={setValue}
            />
          </Box>

          <div style={{ textAlign: "right", margin: "0vh 0%" }}>
            <Button type="submit" variant="contained" endIcon={<BiSend />}>
              Send
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ margin: "3% 1%" }}
              onClick={() => {
                document.forms[0].reset();
                document.querySelector(".ql-editor").innerHTML = "";
                seterr("form cleared");
                setopen(true);
              }}
            >
              cancel
            </Button>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", color: "#06c" }}
            >
              Manage articles
            </Typography>
          </div>
        </Box>
      </Box>

      <Box>
        <ManageArticles />
      </Box>
    </div>
  );
}
Addblog.modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"], // toggled buttons
    ["blockquote"],
    // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    ["video", "image", "link"],

  ], // remove formatting button] 
  imageResize:{
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  }

};


/* 
.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    ['link', 'image', 'video'],
    ['clean'],
    [{width:['10%','20%','30%','40%','50%','60%','70%','80%','90%','100%']}]
  ],
  clipboard: {
    matchVisual: false
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  }
};
*/

export default Addblog;
