import { Typography, TextField, Button, Snackbar, Alert } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef, useState, useEffect, useContext } from "react";
import Panel from "./adminNavBar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BiSend } from "react-icons/bi";
import { updateDoc, collection, doc } from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref as sRef,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { db } from "../../firebase/firebase-config";
import ArticleContext from "../getArticles";

function UpdateBlog() {
  const [open, setopen] = useState(false);
  const [blog, setblog] = useState([]);
  const [file, setfile] = useState("");
  const [err, seterr] = useState("");
  const [value, setvalue] = useState("");
  const content = useRef();
  const articles = useContext(ArticleContext);

  //firebase references
  const storage = getStorage();
  const articlesref = collection(db, "Articles");

  const sendArticle = (e) => {
    e.preventDefault();
    e.target[23].innerText = "LOADING...";
    const id = window.location.href.split("=")[1];
    const docRef = doc(db, "Articles", id);
    if (file[0] === "h") {
      console.log("image not changed");
      updateDoc(docRef, {
        title: e.target[0].value,
        description: e.target[1].value,
        content: content.current.value,
        date: Date(),
      }).then(() => {
        setopen(true);
        window.location.href = "/addblog";
      });
    } else {
      const file = e.target[2].files[0];
      const name = file.name;
      const meta = {
        contentType: file.type,
      };
      deleteObject(sRef(storage, blog.cover)).then(() => {
        const storageRef = sRef(storage, "coverImages/" + name);
        uploadBytes(storageRef, file, meta).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((url) => {
            updateDoc(docRef, {
              title: e.target[0].value,
              description: e.target[1].value,
              cover: url,
              content: content.current.value,
            }).then(() => {
              setopen(true);
              window.location.href = "/addblog";
            });
          });
        });
      });
    }
  };

  useEffect(() => {
    content.current?.editor.root.setAttribute("spellcheck", "false");
    const id = window.location.href.split("=")[1];
    if (articles.length > 1) {
      articles.forEach((element) => {
        if (element.id === id) {
          console.log(element);
          setblog(element);
          const img = element.cover;
          setvalue(element.content);
          setfile(img);
        }
      });
    }
  }, [articles]);

  console.log(blog.length);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return setopen(false);
    }
    setopen(false);
  };
  return (
    <div>
      <Panel />
      <Snackbar
        open={open}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        {err.length > 2 ? (
          <Alert
            onClose={handleClose}
            variant="filled"
            severity="error"
            sx={{ width: "100%" }}
          >
            {err}
          </Alert>
        ) : (
          <Alert
            onClose={handleClose}
            variant="filled"
            severity="success"
            sx={{ width: "100%" }}
          >
            Article updated successfully !
          </Alert>
        )}
      </Snackbar>
      {blog.length === undefined ? (
        <Box
          sx={{
            display: "grid",
            padding: "10vh 0%",
            margin: "0% auto",
            marginLeft: { xs: "2.5em", md: "10em" },
          }}
        >
          <Box
            component="form"
            onSubmit={sendArticle}
            sx={{
              marginLeft: { xs: "1em", md: "10em" },
              marginRight: "3vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{ textAlign: "center", color: "#06c" }}
            >
              Update Article
            </Typography>
            <TextField
              type="text"
              variant="filled"
              sx={{ margin: "2% 0%", paddingTop: "3%" }}
              required
              name="title"
              label="enter title of the article"
              defaultValue={blog.title}
              size="small"
              maxLength="55"
              minLength="20"
            />
            <TextField
              type="text"
              variant="filled"
              sx={{ margin: "-1% 0%", paddingTop: "3%" }}
              required
              label="enter Article description"
              defaultValue={blog.description}
              size="small"
              maxLength="200"
            />
            <img
              src={file}
              style={{ width: "80%", margin: "3% 0%" }}
              alt="noimage selected"
            />
            <input
              type="file"
              style={{ padding: "1%", fontSize: "large", fontWeight: "100" }}
              onChange={(e) => {
                setfile(URL.createObjectURL(e.target.files[0]));
              }}
            />

            <Box>
              <ReactQuill
                theme="snow"
                ref={content}
                required
                modules={modules}
                value={value}
                onChange={setvalue}
              />
            </Box>

            <div style={{ textAlign: "right", margin: "0vh 0%" }}>
              <Button type="submit" variant="contained" endIcon={<BiSend />}>
                Update
              </Button>
              <Button
                variant="contained"
                color="error"
                sx={{ margin: "3% 1%" }}
                onClick={() => {
                  document.forms[0].reset();
                  document.querySelector(".ql-editor").innerHTML = "";
                  seterr("form cleared");
                  setopen(true);
                }}
              >
                cancel
              </Button>
            </div>
          </Box>
        </Box>
      ) : (
        console.log("waiting")
      )}
    </div>
  );
}

const modules = {
  toolbar: [
    ["bold", "italic", "underline"], // toggled buttons
    ["blockquote"],
    // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    ["video", "image", "link"],
  ], // remove formatting button]
};

export default UpdateBlog;
