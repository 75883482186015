import React from "react";
import { Box, Rating, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function SliderViews() {
  const slides = [
    {
      name: "Olga Mavilane",
      review: "suplementos muito bons",
      problems: "joelhos ruins,muito fraco",
      content:
        "Saudações,quero falar do AG cera,é um suplemento muito bom,eu sou testemunha, eu comecei a usar em Outubro,eu tinha muitas problemas de saúde mas superei graças a AG cera, eu não conseguia levantar o  meu braço defeito mas já faço com facilidade, não podia conduzir mas de 50 km, passava muito mal com o joelho mas graças a  AG cera passou, vamos apostar neste suplemento é muito bom, ajudei muitas pessoas no seio familiar é isso pessoal.",
      img: "https://firebasestorage.googleapis.com/v0/b/agcera-33971.appspot.com/o/AGCERA%20TESTIMONIALS%2FWhatsApp%20Image%202023-01-31%20at%2010.26.55%20PM.jpeg?alt=media&token=a918cc23-fced-4bfd-b8ef-c533ca3f3d49",
      rating: 4,
    },
    {
      name: "Matilde Anastácio Mavi",
      review: "me devolveu minha vida",
      problems: "Gastrite aguda,período irregular",
      content:
        "O Suplemento AG CERA , devolveu me a vida , levei muitos anos  a sofrer de gastrite aguda, comia mas não  resultava em nada , tinha problemas sérios no joelho direito , até criava ruído ao caminhar, tinha período não regular, dores fores de cabeça associadas a turves na visão. E quando descobri o suplemento AG Cera em Setembro de 2022 , usei dois pacotes e de um nada a gastrite deixou de ser aquele pesadelo que me atormentava , meu período já é regular e com dias muito reduzidos , o problema de joelho foi resolvido.",
      img: "https://firebasestorage.googleapis.com/v0/b/agcera-33971.appspot.com/o/AGCERA%20TESTIMONIALS%2FWhatsApp%20Image%202023-01-31%20at%2010.26.07%20PM.jpeg?alt=media&token=bbe50e32-318d-4e0c-a806-2d6ed5690ba0",
      rating: 4.5,
    },
    {
      name: "Lucrencia Contente",
      review: "ME SENTI BEM EM DIAS",
      problems: "cólicas,",
      content:
        "Saudações, Mando esta mensagem nesta manha para lhe agradeçer pelo facto de ter me recomendado usar AGCERA. Eu posso dizer que me ajudo bastante por que eu passava muito mal de colicas fortes mesmo.Mais quando usei o ACERA senti uma mudança em mi",
      img: "https://firebasestorage.googleapis.com/v0/b/agcera-33971.appspot.com/o/AGCERA%20TESTIMONIALS%2FWhatsApp%20Image%202023-01-31%20at%2010.30.14%20PM.jpeg?alt=media&token=cef0ff65-8915-4962-819b-ff6a2c3b8996",
      rating: 3.5,
    },
    {
      name: "Rita Couana",
      review: "ÓTIMO SUPLEMENTO",
      problems: "visão,desmaiar",
      content:
        "Saudações! kero falar do Agcera é um suplemento muito bom, ajudou me muito tinha problema da vista mesmo usando óculos não conseguia ver longe mas agora mesmo sem óculos vejo, tinha problema d dores nas articulações não podia ficar muito tempo em pé, não aguentava caregar 10 kg sofria muito de dores mas agora carrego sem nenhum problema graças a AGCera.por isso vamos usar esse suplemento de células troncos AGcera é muitíssimo bom! agradeço  muito a Deus e AGcera mesmo.",
      img: "https://firebasestorage.googleapis.com/v0/b/agcera-33971.appspot.com/o/AGCERA%20TESTIMONIALS%2FWhatsApp%20Image%202023-01-31%20at%2010.28.54%20PM.jpeg?alt=media&token=bcd5c4b1-0868-4d88-943f-04620dfbbf23",
      rating: 4,
    },
    {
      name: "Olga Mavilane",
      review: "suplementos muito bons",
      problems: "Dores nos joelhos, corpo fraco",
      content:
        "Saudações,quero falar do AG cera,é um suplemento muito bom,eu sou testemunha, eu comecei a usar em Outubro,eu tinha muitas problemas de saúde mas superei graças a AG cera, eu não conseguia levantar o  meu braço defeito mas já faço com facilidade, não podia conduzir mas de 50 km, passava muito mal com o joelho mas graças a  AG cera passou, vamos apostar neste suplemento é muito bom, ajudei muitas pessoas no seio familiar é isso pessoal.",
      img: "https://firebasestorage.googleapis.com/v0/b/agcera-33971.appspot.com/o/AGCERA%20TESTIMONIALS%2FWhatsApp%20Image%202023-01-31%20at%2010.26.55%20PM.jpeg?alt=media&token=a918cc23-fced-4bfd-b8ef-c533ca3f3d49",
      rating: 4,
    },
    {
      name: "Lucrencia Contente",
      review: "ME SENTI BEM EM DIAS",
      problems: "cólicas,",
      content:
        "Saudações, Mando esta mensagem nesta manha para lhe agradeçer pelo facto de ter me recomendado usar AGCERA. Eu posso dizer que me ajudo bastante por que eu passava muito mal de colicas fortes mesmo.Mais quando usei o ACERA senti uma mudança em mi",
      img: "https://firebasestorage.googleapis.com/v0/b/agcera-33971.appspot.com/o/AGCERA%20TESTIMONIALS%2FWhatsApp%20Image%202023-01-31%20at%2010.30.14%20PM.jpeg?alt=media&token=cef0ff65-8915-4962-819b-ff6a2c3b8996",
      rating: 3.5,
    },
    {
      name: "Rita Couana",
      review: "ÓTIMO MEDICAMENTO",
      problems: "visão,desmaiar",
      content:
        "Saudações! kero falar do Agcera é um suplemento muito bom, ajudou me muito tinha problema da vista mesmo usando óculos não conseguia ver longe mas agora mesmo sem óculos vejo, tinha problema d dores nas articulações não podia ficar muito tempo em pé, não aguentava caregar 10 kg sofria muito de dores mas agora carrego sem nenhum problema graças a AGCera.por isso vamos usar esse suplemento de células troncos AGcera é muitíssimo bom! agradeço  muito a Deus e AGcera mesmo.",
      img: "https://firebasestorage.googleapis.com/v0/b/agcera-33971.appspot.com/o/AGCERA%20TESTIMONIALS%2FWhatsApp%20Image%202023-01-31%20at%2010.28.54%20PM.jpeg?alt=media&token=bcd5c4b1-0868-4d88-943f-04620dfbbf23",
      rating: 4,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 2004,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 580,
        settings: {
          autoplay: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div style={{ background: "#fbfbfb" }}>
      <Slider {...settings}>
        {slides.map(
          ({ name, review, img, index, rating, problems, content }) => {
            return (
              <Box
                key={img}
                sx={{
                  transition: ".5s",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "0px 0px 40px #d5d5d5",
                  width: { xs: "90%", md: "fit-content" },
                  height: { xs: "fit-content", md: "90vh" },
                  margin: "20vh 0%",
                  boxSizing: "border-box",
                  borderRadius: { xs: "15px", md: "20px" },
                  padding: "1% 3%",
                  background: "white",
                  scale: "0.95",
                  ":hover": {
                    background: "white",
                    scale: "1.01",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      borderRadius: "50%",
                      width: "250px",
                      height: "250px",
                      transform: "translateY(-15vh)",
                      margin: "0% auto",
                      boxShadow:
                        "6px 13px 20px #9b9b9b7a,0px 0px 0px 5px #d0d0d0",
                    }}
                    src={img}
                  />
                  <Box
                    sx={{
                      transform: "translateY(-12vh)",
                      padding: "0%",
                      boxSizing: "border-box",
                      display: "grid",
                      justifyContent: "center",
                    }}
                  >
                    <Rating
                      sx={{
                        margin: "4% 0% 1%",
                        fontSize: { xs: "x-large", md: "xx-large" },
                        display: "flex",
                        justifyContent: "center",
                      }}
                      name="half-rating-read"
                      value={rating}
                      precision={0.5}
                      readOnly
                    />
                    <Typography
                      sx={{
                        fontSize: { xs: "x-large", md: "3vh" },
                        color: "#202086",
                        textAlign: "center",
                        boxSizing: "border-box",
                      }}
                      variant="h5"
                    >
                      {name}
                    </Typography>
                    <Typography
                      sx={{
                        color: "grey",
                        textAlign: "center",
                        fontSize: "medium",
                      }}
                      variant="p"
                    >
                      {problems}
                    </Typography>
                  </Box>
                </Box>

                <Typography
                  sx={{
                    color: "#D46666",
                    transform: "translateY(-12vh)",
                    fontFamily: "sans-serif",
                    textAlign: "center",
                    width: "100%",
                    fontWeight: "bold",
                  }}
                  variant="h5"
                >
                  {review}
                </Typography>

                <Box
                  sx={{
                    fontSize: { xs: "large", md: "large" },
                    textAlign: "center",
                    transform: "translateY(-10vh)",
                    margin: "1%",
                    display: "block",
                    lineHeight: 1.4,
                    fontFamily: "serif",
                    padding: "0% 0%",
                    color: "#1e1e1e",
                  }}
                  variant="p"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
                <Box
                  sx={{
                    width: { xs: "100%", md: "10em" },
                    transform: {
                      xs: "translate(0)",

                      md: "translate(200px,3vh)",
                    },
                    display: "grid",
                    justifyContent: "center",
                    margin: "2%",
                    position: { xs: "relative", md: "absolute" },
                  }}
                ></Box>
              </Box>
            );
          }
        )}
      </Slider>
    </div>
  );
}

export default SliderViews;
