import React from "react";
import {
  FaInstagram,
  FaWhatsapp,
  FaFacebook,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import { Box } from "@mui/material";
function Socials() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        width: "3vw",
        height: "55vh",
      }}
    >
      <Box sx={{ display: { md: "grid", xs: "none" } }}>
        <a
          href="https://www.youtube.com/@agceramoz"
          target="_blank"
          rel="noreferrer"
        >
          <FaYoutube
            style={{ fontSize: "2em", color: "rgba(87, 19, 19, 0.56)" }}
          />
        </a>
      </Box>
      <a href="https://wa.link/lz0x3j" target="_blank" rel="noreferrer">
        <FaWhatsapp
          style={{ fontSize: "2em", color: "rgba(87, 19, 19, 0.56)" }}
        />
      </a>
      <a
        href="https://web.facebook.com/AGNUTRITIONMOZAMBIQUE/"
        target="_blank"
        rel="noreferrer"
      >
        <FaFacebook
          style={{ fontSize: "2em", color: "rgba(87, 19, 19, 0.56)" }}
        />
      </a>
      <Box sx={{ display: { md: "grid", xs: "none" } }}>
        <a
          href="https://www.tiktok.com/@agceramoz"
          target="_blank"
          rel="noreferrer"
        >
          <FaTiktok
            style={{
              fontSize: "2em",
              color: "rgba(87, 19, 19, 0.56)",
            }}
          />
        </a>
      </Box>
      <a
        href="https://www.instagram.com/agceramoz/"
        target="_blank"
        rel="noreferrer"
      >
        <FaInstagram
          style={{ fontSize: "2em", color: "rgba(87, 19, 19, 0.56)" }}
        />
      </a>
    </Box>
  );
}
export default Socials;
