import * as React from "react";
import {
  Alert,
  Snackbar,
  Radio,
  RadioGroup,
  Grid,
  Container,
  Typography,
  Box,
  Checkbox,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
} from "@mui/material";
import Footer from "./footer";
import Navbar from "./navbar";
import { db } from "../firebase/firebase-config.js";
import { collection, addDoc } from "firebase/firestore";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import i18n from "./i18n";
import { useTranslation } from "react-i18next";

function Consult() {
  //  ---------translate ----------------
  const { t } = useTranslation();
  const [language, setLanguage] = useState("po");
  const handleLanguageChange = () => {
    if (language === "en") {
      setLanguage("po");
      i18n.changeLanguage("po");
    } else {
      setLanguage("en");
      i18n.changeLanguage("en");
    }
  };

  // ----styles ----
  const style1 = {
    fontSize: "larger",
    fontFamily: "serif",
    textAlign: { xs: "justify", md: "left" },
    color: "#1a1a1a",
  };

  const senderbtn = useRef();
  const form = useRef();

  //  -----------function to handle submit ----------------
  const [success, setSucces] = useState(false);
  const consCollection = collection(db, "consultationCollection");

  const handleSubmit = async (event) => {
    event.preventDefault();
    senderbtn.current.textContent = "LOADING...";
    const data = new FormData(event.currentTarget);
    await addDoc(consCollection, {
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      email: data.get("email"),
      country: data.get("country"),
      phone: data.get("p hone"),
      city: data.get("city"),
      height: data.get("height"),
      age: data.get("age"),
      gender: data.get("gender"),
      details: data.get("details"),
      time: Date(),
    }).then(() => {
      senderbtn.current.textContent = "SEND";
      setSucces(true);
    });

    // --- send email then ----
    emailjs
      .sendForm(
        "service_obvw1nx",
        "template_bmn937f",
        form.current,
        "MLo95sPsIGhLtqPTd"
      )
      .then(
        (result) => {
          console.log(result);
        },
        (error) => {
          console.log(error.text);
        }
      );

    event.target.reset();
  };
  const handleClose = (event, reason) => {
    setSucces(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={success}
        severity="success"
        onClose={handleClose}
        autoHideDuration={4000}
      >
        <Alert
          severity="success"
          variant="filled"
          onClose={handleClose}
          sx={{ with: "100%" }}
        >
          Response Successfully sent
        </Alert>
      </Snackbar>

      <Navbar language={language} />

      <Typography
        variant="h3"
        sx={{
          textAlign: "center",
          fontWeight: "700",
          padding: "17vh 0 10vh 0",
          color: "#193984",
          fontSize: { xs: "x-large", md: "xx-large" },
        }}
      >
        {t("cons")}
      </Typography>

      <Grid
        container
        maxWidth={{ xs: "90vw", md: "unset" }}
        sx={{
          marginLeft: { md: "unset", xs: "3%" },
          padding: { md: "0 17%", xs: "unset" },
        }}
      >
        {/* --------- video --------  */}
        <Grid item sm={12} mb={5}>
          <Typography
            component="p"
            sx={{
              margin: { xs: "0%", sm: "2vh 1%" },
              textAlign: "justify",
              fontSize: "larger",
              fontFamily: "serif",
              color: "#1a1a1a",
              fontWeight: "bold",
            }}
          >
            {t("watch")}
          </Typography>


          <iframe
            title="youtube video"
            style={{ border: "none", marginTop: "20px" }}
            height="400"
            width={"99%"}
            src="https://www.youtube.com/embed/403frpMWzts?autoplay=1&mute=1"
          ></iframe>
        </Grid>

        <Grid>
          <Typography component="p" pb={2} sx={style1}>
            {t("cons1")}
          </Typography>

          <Typography component="p" pb={2} sx={style1}>
            {t("cons2")}
          </Typography>

          <Typography component="p" pb={2} sx={style1}>
            {t("cons3")}
          </Typography>

          <Typography component="p" pb={2} sx={style1}>
            {t("cons4")}
          </Typography>

          <Typography component="p" pb={2} sx={style1}>
            {t("cons5")}
          </Typography>

          <Typography
            component="p"
            pb={2}
            sx={style1}
            style={{ fontWeight: "bold" }}
          >
            {t("cons6")}
          </Typography>
        </Grid>
      </Grid>

      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 0,
            marginBottom: 20,
            padding: "14vh 0vh ",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            sx={{
              position: "fixed",
              padding: "5px 10px",
              top: "12vh",
              right: "0",
              margin: "20px 0%",
              background: "#f4f4f4",
            }}
            onClick={handleLanguageChange}
          >
            {language === "en"
              ? "Translage to Portguese"
              : "Translate to English"}
          </Button>

          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
            ref={form}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="Nome (name)"
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Sobrenome (surname)"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  type="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="country"
                  label="País (country)"
                  type="text"
                  id="country"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="phone"
                  label="Número de telefone (phone)"
                  name="phone"
                  type="text"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="city"
                  label="Cidade (city)"
                  type="text"
                  id="city"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  fullWidth
                  id="height"
                  label="Altura (cm)"
                  name="height"
                  autoComplete="height"
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  fullWidth
                  id="age"
                  label="Idade (age)"
                  name="age"
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  fullWidth
                  id="weight"
                  label="Peso (kg)"
                  name="weight"
                  type="number"
                />
              </Grid>

              <Grid item xs={12}>
                <p>{t("ent1")} </p>
                <RadioGroup defaultValue="Gender" name="gender" required>
                  <FormControlLabel
                    value="Masculino"
                    control={<Radio />}
                    label="Masculino"
                  />
                  <FormControlLabel
                    value="Feminino"
                    control={<Radio />}
                    label="Feminino"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                <p>{t("ent2")}</p>
                <TextField
                  multiline
                  rows={5}
                  required
                  fullWidth
                  name="details"
                  label="Estado da saúde"
                  type="text"
                  id="details"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  required
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label={t("ent3")}
                />
              </Grid>
              <p style={{ padding: "15px" }}>
                <strong>NOTE: </strong>
                {t("ent4")}
              </p>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                fontSize: "larger",
                background: "rgb(220, 70, 18)",
              }}
              ref={senderbtn}
            >
              {t("send")}
            </Button>
          </Box>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default Consult;
