import React from 'react'
import {FaTrash, FaPen} from 'react-icons/fa'
import {collection, deleteDoc, getDocs, doc} from 'firebase/firestore'
import{useEffect, useState, useRef} from 'react'
import {db} from '../../firebase/firebase-config'
import {Box, Snackbar, Alert, Grid, Button, Typography, Card, CardMedia, CardActions, dividerClasses} from '@mui/material'
import ContentLoader from 'react-content-loader'

function ManageTestimonials () {
    // ______content loader _________ 
    const loading = useRef()

    const MyLoader = () => (
        <ContentLoader style={{margin: "1vh 0vh 5vh"}}>
          <rect x="0" y="0" ry="5" width="340" height="150"/>
          <rect x="0" y="170" ry="4" width="300" height="13"/>
          <rect x="0" y="200" ry="3" width="250" height="10"/>
    
        </ContentLoader>
    )


    // __________________ get testimonials from the database _______________________
    const [open, setopen] = useState(false);
    const [delId, setdelId] = useState("");
    const confirm = useRef()
    const testiCollection = collection(db, 'Testimonials')
    const [testi, setTesti] = useState([])
    
    const getTesti = async() => {
        const testi1 = []
       await getDocs(testiCollection).then((testis) => {
            testis.docs.forEach((testi) => {
                testi1.push({...testi.data(), id:testi.id})
            })
        })
        setTesti(testi1)
        loading.current.style.display="none"
    }

    useEffect(()=>{
        getTesti()
    }, [])

    const Video = (testi2) => {
        return (

            <div>
            <CardMedia component="iframe"
            src={`https://www.youtube.com/embed/${testi2.media}?loop=1`}
            sx={{
              height: "170px",
              boxSizing: "border-box"
            }}>
            </CardMedia>  

            <CardMedia component='img'
            image= {testi2.media}
            sx={{
                height: "170px",
                boxSizing: "border-box"
                }}
            >

            </CardMedia>


            </div>
            
            
            
        )
    }




    const AvailableTesti =  ({testi2}) => {
     
        console.log(testi2)
        return (
          <Grid item xs={12} sm={6} md={4}>
                      <Card sx={{maxWidth: 354}}>
                        
                        {testi2.type === 'photo' ? (
                            <CardMedia component='img'
                                image= {testi2.media}
                                sx={{
                                height: "170px",
                                boxSizing: "border-box"
                                }}
                            >
                
                            </CardMedia>
                        ):(
                            <CardMedia component="iframe"
                                src={`https://www.youtube.com/embed/${testi2.media}?loop=1`}
                                sx={{
                                height: "170px",
                                boxSizing: "border-box"
                                }}>
                            </CardMedia>
                        )}
  
                        <CardActions>
                        <div
                    style={{display: "flex", justifyContent: "space-between"}}
                      >
                    <Button
                      id={testi2.id}
                      startIcon={
                        <FaTrash
                          style={{ color: "red" , marginLeft: "40px"}}
                          onClick={(e) => {
                            confirm.current.style.display = "grid";
                            const prodId =
                              e.target.parentElement.parentElement.parentElement.id;
                            setdelId(prodId);
                            //actually deleting a testimony
                           
                          }}
                        />
                      }

                    >{testi2.clientName}</Button>
                  </div>
                        </CardActions>
  
                      </Card>
                    </Grid>
        )
      }


    return (
        <Box>

            {/* ---- confiramtion ---- */}
                <Box
                sx={{
                display: "none",
                position: "fixed",
                background: "#00000047",
                backdropFilter: "blur(2px)",
                width: "100%",
                height: "100vh",
                zIndex: "10",
                top: "0%",
                left: "0%",
                }}
                ref={confirm}
            >
                <Box
                sx={{
                    background: "white",
                    margin: {
                    xs: "30vh 7vw 0%",
                    sm: "30vh 25vw 0%",
                    md: "30vh 35vw 0%",
                    },
                    height: "30vh",
                    width: "35vw",
                    minWidth: "18em",
                    padding: "2% 3% 1%",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 20px grey",
                }}
                >
                <Typography variant="h5" sx={{ fontWeight: "600",
                fontSize: {xs: "18px", md: "20px"}
                }}>
                    Are you sure you want to delete this Testimony?
                </Typography>
                <p style={{ margin: "2% 0% 2%", fontSize: "larger" }}>
                    by clicking yes im confirming that i full understand the
                    consequences of my action ,
                    <span style={{color: "red" }}>this action can't be undone</span>
                </p>
                <div style={{textAlign: "right" }}>
                    <Button
                    variant="contained"
                    color="error"
                    sx={{fontWeight: "bold" }}
                    onClick = {(e) => {
                        e.target.textContent = 'LOADING...'
                        deleteDoc(doc(db, 'Testimonials', delId)).then(() => {
                        // --- already deleted, reset all features ----
                        e.target.textContent = 'YES'
                        confirm.current.style.display = "none"
                        setopen(true)
                        console.log(`you delete ${delId}`)
                        
                        })
                    }}
                    >
                    YES
                    </Button>
                    <Button
                    variant="contained"
                    sx={{
                        background: "grey",
                        marginLeft: "3%",
                        fontWeight: "bold",
                        "&:hover": { background: "black" },
                    }}
                    onClick={(e) => {
                        confirm.current.style.display = "none";
                    }}
                    >
                    cancel
                    </Button>
                </div>
                </Box>
            </Box>


             {/* ------ displaying uploaded products -------- */}

        <Typography component="h3" variant="h4" sx={{paddingTop: "80px",color:"rgb(1, 73, 144)" , fontSize: "20px", fontWeight: "bold", 
                marginBottom: "15px",
                textAlign: "center"}}>Vie<span style={{borderBottom: "3px solid"}}>w Testimoni</span>als</Typography>
               
            <Box
            sx={{
            position: "fixed",
            display: "flex",
            background: "white",
            flexWrap: "wrap",
            margin:"2% 10px",
            }}
            ref= {loading}
            >
            <MyLoader/><MyLoader/><MyLoader/>
            <MyLoader/><MyLoader/><MyLoader/>
            <MyLoader/><MyLoader/><MyLoader/>

            </Box>
            <Grid container sx={{padding: "30px 5% 2% 8%", width: {xs: "100%", md:"80%"}, margin: "auto"}} spacing={3}>
            {
            testi.map((testi2) => (
                <AvailableTesti testi2 = {testi2} key={testi2.id}/>
            ))
            }
                

             </Grid>


            
        </Box>
    )
}

export default ManageTestimonials