import * as React from "react";
import {
  Typography,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Box, Button, Alert, Snackbar
} from "@mui/material";
import MessageContext from "./getmessages";
import { useState, useEffect, useContext } from "react";
import { MdOutlineExpandMore } from "react-icons/md";
import { useRef } from "react";
import ContentLoader from "react-content-loader";
import {FaTrash} from 'react-icons/fa'
import { db } from "../../firebase/firebase-config.js";
import { collection,deleteDoc, doc } from "firebase/firestore";

export default function ViewMessages() {

  const [delId, setdelId] = useState("");
  const [open, setopen] = useState(false)
  const [people, setpeople] = useState([]);
  const confirm = useRef()

  // -----getting the data from the database ----------
  const messages = useContext(MessageContext);

  useEffect(()=>{
    setpeople(messages)
  },[messages])

  let count = 0;
  const content = useRef();

  const dropDown = (e) => {
    count++;
    if (count % 2 !== 0) {
      if (e.target.nextSibling == null) {
        e.target.parentElement.style.transform = "rotateZ(180deg)";
        e.target.parentElement.nextSibling.style.display = "grid";
      } else {
        e.target.style.transform = "rotateZ(180deg)";
        e.target.nextSibling.style.display = "grid";
      }
    } else {
      if (e.target.nextSibling == null) {
        e.target.parentElement.nextSibling.style.display = "none";
        e.target.parentElement.style.transform = "rotateZ(0deg)";
      } else {
        e.target.nextSibling.style.display = "none";
        e.target.style.transform = "rotateZ(0deg)";
      }
    }
  };

  const MyLoader = () => (
    <ContentLoader>
      <rect x="0" y="0" rx="5" ry="5" width="340" height="70" />
      <rect x="0" y="80" rx="4" ry="4" width="300" height="13" />
      <rect x="0" y="110" rx="3" ry="3" width="250" height="10" />
    </ContentLoader>
  );
  const loading = useRef();

  const PersonCard = ({ person1 }) => {
    return (
      <Card
        sx={{ maxWidth: 345 }}
        style={{
          margin: "1%",
          padding: "1% 2% 1%",
          boxShadow: "rgba(72, 72, 72, 0.34) 2px 4px 30px",
          backdropFilter: "blur(20px)",
          background: "rgb(255, 255, 255)",
          position: "relative"
        }}
      >
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: "rgb(1, 73, 144)", fontSize: "15px"}} aria-label="recipe">
              {person1.name[0]}
            </Avatar>
          }
          title={`${person1.name}`}
          subheader={`${person1.date.split("GMT")[0]}`}
          
        />
        <FaTrash
                style={{color: "red", position: "absolute", right: ".7rem", bottom: "5%", fontSize: "12px", paddingTop: "5px", cursor: "pointer"}}
              onClick={() => {setdelId(person1.id); console.log(person1.id);
                confirm.current.style.display = "grid";
              }}
            />
        <MdOutlineExpandMore
          style={{
            background: "#ebe6fb",
            fontSize: "x-large",
            borderRadius: "50%",
            float: "right",
            margin: "-7vh 0%",
          }}
          onClick={dropDown}
        />

        <CardContent ref={content} style={{ display: "none", margin: "0%" }}>
          <Typography paragraph sx={{ margin: "0%", fontSize: "13px" }}> <strong>Email: </strong>
            {person1.email}
          </Typography>
          <Typography paragraph sx={{ margin: "0%", fontSize: "13px" }}>
            {person1.content}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  // ____ handle close____  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway'){
      return;
    }

    setopen(false);
  }

  return (

    <Box>

        <Snackbar open={open} onClose={handleClose} anchorOrigin={{vertical: "bottom", horizontal: "center"}} autoHideDuration={4000}>
          <Alert severity="error" variant="filled" sx={{width: "100%", padding: "2px 10px"}}>
            Message deleted. Reload if you nolonger want to see it!
          </Alert>

        </Snackbar>

              {/* ---- confiramtion ---- */}
              <Box
        sx={{
          display: "none",
          position: "fixed",
          background: "#00000047",
          backdropFilter: "blur(2px)",
          width: "100%",
          height: "100vh",
          zIndex: "10",
          top: "0%",
          left: "0%",
        }}
        ref={confirm}
      >
        <Box
          sx={{
            background: "white",
            margin: {
              xs: "30vh 7vw 0%",
              sm: "30vh 25vw 0%",
              md: "30vh 35vw 0%",
            },
            height: "30vh",
            width: "35vw",
            minWidth: "18em",
            padding: "2% 3% 1%",
            borderRadius: "10px",
            boxShadow: "0px 0px 20px grey",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "600",
          fontSize: {xs: "18px", md: "20px"}
        }}>
            Are you sure you want to delete this message?
          </Typography>
          <p style={{ margin: "2% 0% 2%", fontSize: "larger" }}>
            by clicking yes im confirming that i full understand the
            consequences of my action ,
            <span style={{color: "red" }}>this action can't be undone</span>
          </p>
          <div style={{textAlign: "right" }}>
            <Button
              variant="contained"
              color="error"
              sx={{fontWeight: "bold" }}
              onClick = {(e) => {
                e.target.textContent = 'LOADING...'
                deleteDoc(doc(db, 'messages', delId)).then(() => {
                  // --- already deleted, reset all features ----
                  e.target.textContent = 'YES'
                  confirm.current.style.display = "none"
                  setopen(true)                  
                })
              }}
            >
              YES
            </Button>
            <Button
              variant="contained"
              sx={{
                background: "grey",
                marginLeft: "3%",
                fontWeight: "bold",
                "&:hover": { background: "black" },
              }}
              onClick={(e) => {
                confirm.current.style.display = "none";
              }}
            >
              cancel
            </Button>
          </div>
        </Box>
      </Box>


              {/* _____ map the messages ______  */}

      
      <div
        style={{
          background: "#f7f7f7",
          padding: "2% 0.5% 5%",
          width: "20vw",
          minWidth: "20em",
          top: "7vh",
          boxShadow: "2px 4px 30px #4848481c",
          right: "0%",
          position: "fixed",
          zIndex: 20,
        }}
      >
        {people?.length > 0 ? (
          people.map((person, id) => <PersonCard key={id} person1={person} />)
        ) : (
          <div className="container2">
            <Box
              sx={{ display: "grid", justifyContent: "left", width: "100vw" }}
              ref={loading}
            >
              <MyLoader />
              <MyLoader />
              <MyLoader />
              <MyLoader />
              <MyLoader />
              <MyLoader />
              <MyLoader />
              <MyLoader />
            </Box>
          </div>
        )}
      </div>

      </Box>
  );
}
