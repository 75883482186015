import React, { useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import logo from "./assets/logo.png";
import { FaBars } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import i18n from "./i18n";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

function Navbar({ language }) {
  const [count, setcount] = useState(1);
  const [sidebar, setsidebar] = useState({
    position: { xs: "fixed", md: "relative" },
    top: { xs: "9vh", md: 0 },
    zIndex: 0,
    clipPath: {
      xs: "polygon(0 0, 0 0, 0 100%, 0% 100%)",
      md: "polygon(0 0, 100% 0, 100% 100%, 0 100%);",
    },
    justifyContent: "right",
    width: "90vw",
    transition: "1s ease",
  });
  const { t } = useTranslation();
  useEffect(() => {
    if (language === undefined) {
      i18n.changeLanguage("en");
    }
  }, []);

  const sideBar = () => {
    setcount(count + 1);
    count % 2 !== 0
      ? setsidebar({
          clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
          flexDirection: "column",
          backgroundColor: "#fdfdfd",
          width: "100vw",
          height: "90vh",
          top: "8.9vh",
          position: "fixed",
          left: "0%",
        })
      : setsidebar({
          clipPath: {
            xs: "polygon(0 0, 0 0, 0 100%, 0% 100%)",
            md: "polygon(0 0, 100% 0, 100% 100%, 0 100%);",
          },
          flexDirection: "column",
          width: "100vw",
          backgroundColor: "#fcfcfc",
          position: "fixed",
          left: "0",
          height: "90vh",
          top: "8.9vh",
        });
  };
  const linkStyle = {
    listStyle: "none",
    fontSize: { xs: "x-large", md: "1.4em" },
    transition: ".3s",
    borderBottom: "5px solid white",
    height: "9vh",
    fontWeight: "100",
    padding: "1.3% 0.8%",
    textTransform: "lowercase",
    boxSizing: "border-box",
    "&:hover": {
      color: "black",
      backgroundColor: "#f9f9f9",
      borderColor: "#1a6fc4;",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        zIndex: "10",
        justifyContent: "space-between",
        padding: { xs: "0% 3% 1%", sm: "0% 5% 0%", md: "0% 1%" },
        position: "fixed",
        width: "100vw",
        boxSizing: "border-box",
        background: "white",
        boxShadow: "2px 2px 30px #d0d0d065",
      }}
    >
      <Box
        component={count % 2 === 0 ? FaTimes : FaBars}
        sx={{
          zIndex: "10",
          display: { md: "none" },
          transition: "1s ease",
          fontSize: "2em",
          color: "#033595",
          margin: { xs: "2vh 0%", md: "2.5vh 0%" },
        }}
        onClick={() => {
          sideBar();
        }}
      />
      <a href="/">
        <Box
          src={logo}
          component="img"
          alt=""
          sx={{
            zIndex: "10",
            width: { xs: "9em", md: "10em" },
            maxHeight: "9vh",
            margin: "-2% 1%",
          }}
        />
      </a>
      <Stack direction="row" style={{ transition: "1s ease" }} sx={sidebar}>
        <Box component="li" id="nav_li" sx={linkStyle}>
          <NavLink
            style={{ textDecoration: "none", color: "rgb(18, 50, 134)" }}
            to="/"
          >
            {t("home")}
          </NavLink>
        </Box>
        <Box component="li" id="nav_li" sx={linkStyle}>
          <NavLink
            style={{
              textDecoration: "none",
              color: "rgb(18, 40, 134)",
              fontWeight: "light",
            }}
            to="/consult"
          >
            {t("consult")}
          </NavLink>
        </Box>
        <Box component="li" id="nav_li" sx={linkStyle}>
          <NavLink
            style={{
              textDecoration: "none",
              color: "rgb(18, 40, 134)",
              fontWeight: "light",
            }}
            to={"/aboutHealth"}
          >
            {t("health")}
          </NavLink>
        </Box>
        <Box component="li" id="nav_li" sx={linkStyle}>
          <NavLink
            style={{
              textDecoration: "none",
              color: "rgb(18, 40, 134)",
              fontWeight: "light",
            }}
            to="/products"
          >
            {t("products")}
          </NavLink>
        </Box>

        <Box component="li" id="nav_li" sx={linkStyle}>
          <NavLink
            style={{
              textDecoration: "none",
              color: "rgb(18, 40, 134)",
              fontWeight: "light",
            }}
            to="/aboutus"
          >
            {t("aboutus")}
          </NavLink>
        </Box>
        <Box
          component="li"
          id="nav_li"
          className="special_item"
          sx={{
            display: "none",
            margin: "1vh% 0%",
            listStyle: "none",
            fontSize: "2.8vh",
            fontFamily: "sans-serif",
            fontWeight: "450",
            transition: ".3s",
            borderBottom: "5px solid white",
            height: "9vh",
            padding: "2.7vh 0%",
            boxSizing: "border-box",
            "&:hover": {
              color: "black",
              backgroundColor: "#f9f9f9",
              borderColor: "#1a6fc4;",
            },
          }}
        >
          <NavLink
            style={{
              textDecoration: "none",
              color: "rgb(18, 40, 134)",
              fontWeight: "light",
            }}
            to="/aboutHealth"
          >
            {t("search").toUpperCase()}
          </NavLink>
        </Box>

        {/* __________________________ */}
        <Box component="li" id="nav_li" sx={linkStyle}>
          <NavLink
            style={{
              textDecoration: "none",
              color: "rgb(18, 40, 134)",
              fontWeight: "light",
            }}
            to="/testimonials"
          >
            TESTEMUNHOS
          </NavLink>
        </Box>

        {/* __________________________ */}
        <Box component="li" id="nav_li" sx={linkStyle}>
          <NavLink
            style={{
              textDecoration: "none",
              color: "rgb(18, 40, 134)",
              fontWeight: "light",
            }}
            to="/membership"
          >
            {t("bcmM")}
          </NavLink>
        </Box>
      </Stack>
      <a
        href="#subscribe"
        style={{ textDecoration: "none", marginRight: ".5%" }}
      >
        <Button
          variant="contained"
          size="medium"
          sx={{
            margin: { xs: "1.2vh 0%", md: "1.6vh 0%" },
            display: "block",
            width: "max-content",
            borderRadius: "30px",
          }}
        >
          {t("subscribe")}
        </Button>
      </a>
    </Box>
  );
}

export default Navbar;
