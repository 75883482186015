import React,{useState} from 'react'
import { Box,Button,TextField,FormControlLabel,Typography,Snackbar,Alert,Grid } from '@mui/material'
import {FaUserCircle} from "react-icons/fa"
import Panel from './adminNavBar';
import { UserAuth } from '../../auth';



function Addmember() {
    const [open, setopen] = useState(false)
    const [err, seterr] = useState("")
    //adding a user to firestore for authentication
    const {createUser}=UserAuth()
    const handleaddEmployee= async(event)=>{
        seterr("")
        event.preventDefault()
        const {email,password}=event.target.elements
        try{
            await createUser(email.value,password.value).then(()=>{
                setopen(true)
            }) 
        }
        catch(error){
            const custom =error.message.split("auth/")[1]
            seterr(custom)
            console.log(custom)
            setopen(true)
        }

    }

    const handleClose=(event, reason)=>{
        if (reason === 'clickaway') {
            return;
            setopen(false);
          }
      
    }
   

  return (
    <Box>
      <Panel />
    <Box sx={{padding:"7vh 0%"}}>
    {/* <Snackbar open={open} autoHideDuration={1500} onClose={handleClose} anchorOrigin={{ vertical:"bottom", horizontal:"right" }}>
    {err.length>2?<Alert onClose={handleClose} variant="filled"  severity="error" sx={{ width: '100%' }}>
    {err}
  </Alert>:
   <Alert onClose={handleClose} variant="filled"  severity="success" sx={{ width: '100%' }}>
    user successfully created!
  </Alert>}
    </Snackbar> */}
    <Box
          sx={{
            padding:"2% 3% 5vh",
            borderRadius:"20px",
            boxSizing:"border-box",
            marginTop:"5vh",
            marginLeft:{xs:"3em",md:"15em"},
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            <FaUserCircle style={{fontSize:"10vh",color:"#193984",margin:"1% 0% 2%"}}/>
          <Typography component="h1" variant="h5" sx={{color:"rgb(1, 73, 144);"}}>
            ADD EMPLOYEE 
          </Typography>

          <Box component="form" onSubmit={handleaddEmployee}  sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 ,background:"rgb(1, 73, 144);"}}
            >
              ADD EMPLOYEE
            </Button>
            <Grid container>
              <Grid item>
         
     </Grid>
            </Grid>
          </Box>
        </Box>
    </Box>     
    </Box>

  )
}

export default Addmember