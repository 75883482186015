import { Typography, Grid, Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useRef, useEffect } from "react";
import {
  FcShop,
  FcBusinessman,
  FcAssistant,
  FcBiotech,
  FcTemplate,
  FcRating,
} from "react-icons/fc";
import { FaEnvelope } from "react-icons/fa";
import { MdOutlineMenu, MdContactMail } from "react-icons/md";
import { UserAuth } from "../../auth";
import logo from "../assets/logo.png";
import { Navigate, Link } from "react-router-dom";
import ViewMessages from "./viewMessages";

function Panel() {
  const [count, setcount] = useState(1);
  const { user, logout } = UserAuth();
  const username = user.email;

  const logoutHandler = async () => {
    try {
      await logout();
      Navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  const menuHandler = (e) => {
    setcount(count + 1);
    console.log(count);
    count % 2 !== 0
      ? document.querySelectorAll("#dispear").forEach((navitem) => {
          navitem.style.display = "none";
          document.getElementById("side").style.width = "3em";
          e.target.style.color = "#322e66";
        })
      : document.querySelectorAll("#dispear").forEach((navitem) => {
          document.getElementById("side").style.width = "18em";
          navitem.style.display = "block";
          e.target.style.color = "#36aace";
        });
  };

  useEffect(() => {
    if (window.innerWidth < 900) {
      setcount(2);
      document.querySelectorAll("#dispear").forEach((navitem) => {
        navitem.style.display = "none";
        document.getElementById("side").style.width = "3em";
      });
    }
  }, []);

  const messages = useRef();
  let messagecount = 0;
  const showmessages = () => {
    messagecount++;
    if (messagecount % 2 !== 0) {
      messages.current.style.clipPath = "inherit";
    } else {
      messages.current.style.clipPath = "inset(0 0 100% 0)";
    }
  };

  return (
    <div style={{ background: "#f9fafb" }}>
      <Box
        sx={{
          width: "100%",
          zIndex: "20",
          backdropFilter: "blur(20px)",
          transition: ".6s",
          height: "7.2vh",
          boxShadow: "2px 2px 20px #ececec",
          boxSizing: "border-box",
          position: "fixed",
          display: "flex",
          justifyContent: "right",
        }}
      >
        <FaEnvelope
          style={{
            height: "5vh",
            fontSize: "x-large",
            margin: "1vh 0%",
            color: "#6b92ec",
          }}
          onClick={showmessages}
        />
        <Button
          variant="contained"
          sx={{
            background: "#b31c23",
            margin: "1vh 1vw",
            height: { xs: "5vh", md: "5vh" },
            fontSize: "small",
            "&:hover": { background: "#d20800", color: "black" },
          }}
          onClick={logoutHandler}
        >
          LOGOUT
        </Button>
      </Box>
      <Box
        id="side"
        sx={{
          transition: ".4s ease",
          width: "18em",
          height: "100vh",
          borderRight: "1px solid #a6a3a3",
          position: "fixed",
          background: "#f9fbfa",
          zIndex: "20",
        }}
      >
        <MdOutlineMenu
          style={{
            fontSize: "xx-large",
            transition: ".4s",
            color: "#36aace",
            margin: "2% 3%",
          }}
          onClick={menuHandler}
        />
        <a href="/">
          <Box
            component="img"
            src={logo}
            sx={{
              position: "fixed",
              height: { xs: "7vh", sm: "7vh" },
              margin: { xs: "0% 3vw", sm: "0% 2em" },
              top: "-0.5%",
            }}
          ></Box>
        </a>
        <Grid
          id="dispear"
          sx={{
            background: "#e8ebef",
            textAlign: "center",
            padding: "3%",
            margin: "1vh 3%",
            borderRadius: "5px",
          }}
        >
          <FcAssistant
            style={{ height: "5vh", fontSize: "20vh", margin: "2vh 4% 5% 1%" }}
          />
          <Typography
            id="dispear"
            variant="h8"
            sx={{ margin: "1% 2%", display: "block" }}
          >
            {username}
          </Typography>
        </Grid>

        <Grid
          component={Link}
          to="/addblog"
          sx={{
            textDecoration: "none",
            background: "#e8ebef",
            display: "flex",
            padding: "4%",
            margin: "1vh 3%",
            borderRadius: "5px",
            "&:hover": { background: "#d5d5d5" },
          }}
        >
          <FcTemplate
            style={{
              height: "5vh",
              fontSize: "xx-large",
              margin: "0% 4% 0% 1%",
            }}
          />
          <Typography
            id="dispear"
            variant="h6"
            sx={{ margin: "1% 2%", fontSize: "large" }}
          >
            ARTICLES
          </Typography>
        </Grid>

        {/* <Grid
          component={Link}
          to="/employee"
          sx={{
            textDecoration: "none",
            background: "#e8ebef",
            display: "flex",
            padding: "4%",
            margin: "1vh 3%",
            borderRadius: "5px",
            "&:hover": { background: "#d5d5d5" },
          }}
        >
          <FcBusinessman
            style={{
              height: "5vh",
              fontSize: "xx-large",
              margin: "0% 4% 0% 1%",
            }}
          />
          <Typography
            id="dispear"
            variant="h6"
            sx={{ margin: "1% 2%", fontSize: "large" }}
          >
            ADD USERS
          </Typography>
        </Grid> */}

        <Grid
          component={Link}
          to="/Adminconsultation"
          sx={{
            textDecoration: "none",
            background: "#e8ebef",
            display: "flex",
            padding: "4%",
            margin: "1vh 3%",
            borderRadius: "5px",
            "&:hover": { background: "#d5d5d5" },
          }}
        >
          <FcBiotech
            style={{
              height: "5vh",
              fontSize: "xx-large",
              margin: "0% 4% 0% 1%",
            }}
          />
          <Typography
            id="dispear"
            variant="h6"
            sx={{ margin: "1% 2%", fontSize: "large" }}
          >
            CONSULTATIONS
          </Typography>
        </Grid>

        <Grid
          component={Link}
          to="/addTesti"
          sx={{
            textDecoration: "none",
            background: "#e8ebef",
            display: "flex",
            padding: "4%",
            margin: "1vh 3%",
            borderRadius: "5px",
            "&:hover": { background: "#d5d5d5" },
          }}
        >
          <FcRating
            style={{
              height: "5vh",
              fontSize: "xx-large",
              margin: "0% 4% 0% 1%",
            }}
          />
          <Typography
            id="dispear"
            variant="h6"
            sx={{ margin: "1% 2%", fontSize: "large" }}
          >
            TESTIMONIALS
          </Typography>
        </Grid>
        <Grid
          component={Link}
          to="/subs"
          sx={{
            textDecoration: "none",
            background: "#e8ebef",
            display: "flex",
            padding: "4%",
            margin: "1vh 3%",
            borderRadius: "5px",
            "&:hover": { background: "#d5d5d5" },
          }}
        >
          <MdContactMail
            style={{
              height: "5vh",
              fontSize: "xx-large",
              margin: "0% 4% 0% 1%",
            }}
          />
          <Typography
            id="dispear"
            variant="h6"
            sx={{ margin: "1% 2%", fontSize: "large" }}
          >
            SUBSCRIPTIONS
          </Typography>
        </Grid>
        <Grid
          component={Link}
          to="/addProduct"
          sx={{
            textDecoration: "none",
            background: "#e8ebef",
            display: "flex",
            padding: "4%",
            margin: "1vh 3%",
            borderRadius: "5px",
            "&hover": { background: "#d5d5d5" },
          }}
        >
          <FcShop
            style={{
              height: "5vh",
              fontSize: "xx-large",
              margin: "0% 4% 0% 1%",
            }}
          />
          <Typography
            id="dispear"
            vairant="h6"
            sx={{ margin: "1% 2%", fontSize: "large" }}
          >
            PRODUCTS
          </Typography>
        </Grid>
      </Box>
      <Box
        ref={messages}
        sx={{ clipPath: "inset(0 0 100% 0)", transition: "1s" }}
      >
        <ViewMessages />
      </Box>
    </div>
  );
}

export default Panel;
