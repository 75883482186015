import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import Navbar from "./navbar";
import heroBg from "./assets/heroBg.png";
import contactsvg from "./assets/contactsvg.png";
import { MdLocationOn, MdPhoneInTalk, MdSend } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import React, { useState, useRef, useContext, useEffect } from "react";
import ArticleContext from "./getArticles";
import { db } from "../firebase/firebase-config.js";
import { collection, addDoc } from "firebase/firestore";
import Footer from "./footer";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import SliderViews from "./slider";
import ContentLoader from "react-content-loader";
import { BsArrowRight } from "react-icons/bs";
import Socials from "./socials";
import { NavLink, Link } from "react-router-dom";

function Landing() {
  const [bg, setbg] = useState(
    "https://firebasestorage.googleapis.com/v0/b/agcera-33971.appspot.com/o/coverImages%2Fag-brocs.png?alt=media&token=06d07433-f5e4-4701-98d7-88803f730192"
  );
  const [counter, setcounter] = useState(1);
  const [success, setsuccess] = useState(false);
  const messagesCollectionRef = collection(db, "messages");

  const articles = useContext(ArticleContext).slice(0, 3);

  const MyLoader = () => (
    <ContentLoader style={{ margin: "1vh 0vh 5vh" }}>
      <rect x="0" y="0" rx="10" ry="5" width="200" height="200" />
      <rect x="200" y="300" rx="5" ry="5" width="20" height="50" />
    </ContentLoader>
  );
  const loading = useRef();

  const { t } = useTranslation();
  const [language, setLanguage] = useState("po");
  const handleLanguageChange = () => {
    if (language === "en") {
      setLanguage("po");
      i18n.changeLanguage("po");
    } else {
      setLanguage("en");
      i18n.changeLanguage("en");
    }
  };

  const sendbtn = useRef();

  const sendMessages = async (e) => {
    sendbtn.current.textContent = "Loading";
    e.preventDefault();
    await addDoc(messagesCollectionRef, {
      name: e.target[0].value,
      date: Date(),
      email: e.target[2].value,
      content: e.target[4].value,
    }).then(() => {
      setsuccess(true);
      sendbtn.current.textContent = "SEND";
      e.target.reset();
    });
  };
  const handleClose = (event, reason) => {
    setsuccess(false);
  };

  window.onscroll = () => {
    if (document.getElementById("socials_box") === null) {
    } else {
      if (window.scrollY < 450) {
        document.getElementById("socials_box").style.display = "none";
      } else {
        document.getElementById("socials_box").style.display = "grid";
      }
    }
  };
  const photos = [];
  window.visualViewport.width > 900
    ? photos.push(
        { image: heroBg },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/agcera-33971.appspot.com/o/coverImages%2Fag%20mexx.png?alt=media&token=737b238f-66df-497b-90d1-bb2f3b0ad706",
          pitch: "Para homens de verdade",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/agcera-33971.appspot.com/o/coverImages%2FAg-Rosse-Ag-Nutrition-UK.png?alt=media&token=c7be12e0-f5e7-435f-8d8f-6911e6b99aad",
          pitch: "Redefinindo a beleza interior",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/agcera-33971.appspot.com/o/coverImages%2Fag-ceras.png?alt=media&token=31fb2b5c-b185-41e2-84ee-ebb6d26b1547",
          pitch: "O presente da natureza para saude ideal",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/agcera-33971.appspot.com/o/coverImages%2Fag-brocs.png?alt=media&token=06d07433-f5e4-4701-98d7-88803f730192",
          pitch: "Sua imunidade é a nossa prioridade",
        }
      )
    : photos.push(
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/agcera-33971.appspot.com/o/coverImages%2Fmobile%2FAg-Cera2.png?alt=media&token=0212c643-a719-4cd2-98bc-13654980a6eb",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/agcera-33971.appspot.com/o/coverImages%2Fmobile%2FAG-cera.png?alt=media&token=332b60f3-793c-4a8e-93fa-bff3ab6c2178",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/agcera-33971.appspot.com/o/coverImages%2Fmobile%2FAG-Fiberee.png?alt=media&token=c73610a0-5dea-41a6-b54c-da00e78ffb4a",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/agcera-33971.appspot.com/o/coverImages%2Fmobile%2FAg-Maxx.png?alt=media&token=e1698023-25e8-440e-a2ff-ddb8d7c03aac",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/agcera-33971.appspot.com/o/coverImages%2Fmobile%2FAG-Rose.png?alt=media&token=950ae46a-1f51-49c0-9ec0-9f79ba87b50f",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/agcera-33971.appspot.com/o/coverImages%2Fmobile%2FAG-drink.png?alt=media&token=cdee0395-6ad3-4c1f-895b-1a46f298c599",
        }
      );

  useEffect(() => {
    setTimeout(() => {
      if (counter < photos.length) {
        setbg(photos[counter]);
        setcounter(counter + 1);
      } else {
        setcounter(0);
      }
    }, 3500);
  }, [counter]);
  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={success}
        severity="success"
        onClose={handleClose}
        autoHideDuration={2000}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Message Successfully Sent!
        </Alert>
      </Snackbar>
      <Navbar language={language} />
      {/* landing hero */}
      <Box
        sx={{ position: "fixed", top: "30vh", left: "2%", display: "none" }}
        id="socials_box"
      >
        <Socials />
      </Box>
      <Box
        sx={{
          minHeight: "105vh",
          width: "100%",
          flexDirection: { xs: "column", md: "row-reverse" },
          display: { xs: "flex", sm: "flex" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "60%" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            sx={{
              position: "fixed",
              top: { xs: "92%", md: "12vh" },
              right: "0%",
              background: { xs: "#f4f4f482", md: "#f4f4f4" },
              backdropFilter: "blur(2px)",
              zIndex: "3",
            }}
            onClick={handleLanguageChange}
          >
            {language === "en"
              ? "Translate to Portguese"
              : "Translate to English"}
          </Button>
          <Typography
            variant="h1"
            sx={{
              textAlign: { xs: "center", md: "right" },
              fontWeight: "bolder",
              color: "#193984",
              fontSize: { xs: "8vw", sm: "6vh", md: "4.5vw" },
              margin: { xs: "12vh 0% 0%", sm: "12vh 0% 0%", md: "28vh 2% 2%" },
              fontFamily: "Bree Serif",
            }}
          >
            {t("hero")}
            <br></br>
            {t("subhero")}
          </Typography>

          <Typography
            variant="p"
            sx={{
              textAlign: { xs: "center", md: "right" },
              fontSize: { xs: "large", sm: "3.5vh" },
              margin: { xs: "4% 5%", sm: "2% 2%" },
              color: "#02131CE6",
              lineHeight: "1.4em",
            }}
          >
            {t("line1")}
          </Typography>
          <Box sx={{ textAlign: { xs: "center", md: "right" } }}>
            <Button
              variant="outlined"
              size="large"
              sx={{
                background: "#d04747",
                margin: "2% 1%",
                fontSize: { xs: "small", md: "large" },
                color: "white",
                border: "3px double white",
                "&:hover": {
                  background: "white",
                  color: "black",
                  border: "3px solid black",
                },
              }}
              href="https://login.agnutritioninternational.com"
              target="_blank"
              rel="noreferrer"
            >
              {t("btn1")}
            </Button>
            <Button
              href="#contactPage"
              variant="contained"
              size="large"
              sx={{
                margin: "2% 2%",
                background: "#193984",
                padding: "1.2% 4%",
                fontSize: { xs: "medium", md: "large" },
              }}
            >
              {t("btn2")}
            </Button>
          </Box>
        </Box>
        <Box
          component={Link}
          sx={{
            width: { xs: "100%", md: "59%" },
            height: { xs: "fit-content", sm: "fit-content", md: "95vh" },
            margin: { xs: "1vh 0% 0%", sm: "2vh 0%", md: "0vh 0%" },
            textDecoration: "none",
          }}
          to="/products"
        >
          <Box
            component="img"
            src={bg.image}
            sx={{
              width: "100%",
              height: { sm: "fit-content", md: "90vh" },
              boxShadow: "2px 2px 10px #00000001;",
              margin: { xs: "1vh 0% 0%", sm: "2vh 0%", md: "5vh -5%" },
              bottom: "0%",
              zIndex: "0",
            }}
          ></Box>
          <Typography
            variant="h3"
            sx={{
              lineHeight: { md: "1em" },
              margin: { xs: "0%", md: "-8vh 5%" },
              fontStyle: "italic",
              fontWeight: "light",
              color: "#193984;",
              fontFamily: "Fasthand, cursive",
              fontSize: { xs: "xx-large", md: "2.5em" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            {bg.pitch}
          </Typography>
        </Box>
      </Box>
      {/* _____________________________________recent articles_______________________________________________________________________________________*/}

      <Typography
        variant="h3"
        sx={{
          margin: "10vh 0% 10vh ",
          textAlign: "center",
          fontWeight: "700",
          color: "#193984;",
          fontSize: { xs: "7vw", md: "3vw" },
          display: "block",
        }}
      >
        {t("latest")}
        <span
          style={{ borderBottom: "5px solid #193984", paddingBottom: ".5%" }}
        >
          {t("und")}
        </span>
        {t("blog")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "space-evenly" },
          margin: "1vh auto",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            width: "100%",
            background: "white",
            top: "15vh",
            margin: "0%",
          }}
          ref={loading}
        >
          <MyLoader />
          <MyLoader />
          <MyLoader />
          <MyLoader />
        </Box>
        <Box sx={{ margin: "5vh 0%" }}></Box>
        {articles.slice(0, 3).map(({ title, cover, description, id }) => {
          if (loading.current !== undefined) {
            loading.current.style.display = "none";
          }
          return (
            <Box
              key={id}
              component={NavLink}
              to={`/read-blog?${id}`}
              sx={{
                minWidth: "15em",
                maxWidth: "22em",
                textDecoration: "none",
                margin: "1vh 2vw",
                maxHeight: "62vh",
                flex: "1 0 20%",
                boxShadow: "1px 1px 20px #d0cece",
                borderRadius: "20px",
                transition: ".2s",
                "&:hover": {
                  scale: "1.1",
                  boxShadow: "1px 1px 20px #9f9d9d",
                  background: "#ffffd291",
                },
              }}
            >
              <Box
                component="img"
                src={cover}
                sx={{
                  width: "100%",
                  height: "200px",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                }}
              />
              <Box sx={{ padding: "2% 4%" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    maxHeight: "11vh",
                    color: "#3e396b",
                    lineHeight: "1.2em",
                    textOverflow: "clip",
                    overflow: "hidden",
                    display: "block",
                    margin: "1% 0% 4%",
                  }}
                >
                  {title}
                </Typography>
                <p
                  style={{
                    height: "7vh",
                    lineHeight: "1.5em",
                    textOverflow: "clip",
                    overflow: "hidden",
                    display: "block",
                    marginBottom: "2vh",
                    color: "#4f4e4e",
                  }}
                >
                  {description}
                </p>
              </Box>
              <Box
                sx={{
                  marginBottom: "4%",
                  display: "flex",
                  marginLeft: "4%",
                  padding: "0% 2%",
                  textDecoration: "none",
                  width: "max-content",
                  "&:hover": { background: "#eeeab1" },
                }}
              >
                <p style={{ color: "#ff5454", minWidth: "fit-content" }}>
                  {t("readMore")}
                </p>
                <BsArrowRight
                  style={{
                    margin: "0% 1%",
                    color: "#ff5454",
                    fontSize: "larger",
                  }}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box sx={{ display: "block", textAlign: "center", margin: "5vh 0%" }}>
        <Button
          component={NavLink}
          variant="contained"
          size="large"
          sx={{
            textDecoration: "none",
            borderRadius: "30px",
            background: "#ff5454",
            transition: ".3s",
            "&:hover": { background: "#ff4454", scale: "1.1" },
          }}
          to="/aboutHealth"
        >
          {t("search")}
        </Button>
      </Box>

      {/* ____________________________________________________________________testimonials _____________________________________________________________________________--*/}

      <Box sx={{ margin: "0vh 0%", minHeight: "50vh" }}>
        <Typography
          variant="h3"
          sx={{
            margin: "30vh 0% 0% ",
            textAlign: "center",
            fontWeight: "700",
            color: "#193984;",
            fontSize: { xs: "7vw", md: "3vw" },
          }}
        >
          Test
          <span
            style={{ borderBottom: "5px solid #193984", paddingBottom: ".5%" }}
          >
            {t("testi")}
          </span>
          {t("ials")}
        </Typography>
        <Box sx={{ margin: "5vh 0%", display: "relative" }}>
          <SliderViews />
        </Box>
      </Box>

      {/* ------------------------contact page ---------------*/}

      <form id="contactPage" onSubmit={sendMessages}>
        <Typography
          variant="h3"
          sx={{
            margin: "15vh 0% 10vh ",
            textAlign: "center",
            fontWeight: "700",
            color: "#193984;",
            fontSize: { xs: "xx-large", md: "3vw" },
          }}
        >
          {t("btn2")}
        </Typography>
        <Box
          sx={{
            width: { xs: "90vw", md: "70vw" },
            minHeight: { xs: "70vh", md: "80vh" },
            boxShadow: "2px 2px 40px #95959580",
            margin: "3% auto",
            display: "flex",
            borderRadius: "20px",
            marginBottom: "10vh",
          }}
        >
          <Box
            sx={{
              width: { xs: "80vw", sm: "45vw", md: "30vw" },
              padding: "4%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                margin: "1vh 0% 5% ",
                textAlign: "center",
                fontWeight: "700",
                color: "#193984;",
                fontSize: { xs: "x-large", md: "3vw" },
              }}
            >
              {t("get")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                margin: "5% 0%",
                justifyContent: "space-evenly",
              }}
            >
              <Box
                component="a"
                href="https://maps.google.com/maps?q=-25.927032470703125%2C32.55077362060547&z=17&hl=pt-BR "
                target="_blank"
                sx={{ textDecoration: "none" }}
              >
                <MdLocationOn
                  style={{
                    fontSize: "5vh",
                    color: "rgb(51, 73, 159)",
                    width: "100%",
                  }}
                />
                <p style={{ color: "rgb(51, 73, 159)" }}>Mozambique </p>
              </Box>
              <Box>
                <FaWhatsapp
                  style={{
                    fontSize: "5vh",
                    color: "rgb(51, 73, 159)",
                    width: "100%",
                  }}
                />
                <p style={{ color: "rgb(51, 73, 159)" }}>+258845541504 </p>
              </Box>
              <Box
                component="a"
                href="tel:+258873750005"
                sx={{
                  textDecoration: "none",
                }}
              >
                <MdPhoneInTalk
                  style={{
                    fontSize: "5vh",
                    color: "rgb(51, 73, 159)",
                    width: "100%",
                  }}
                />
                <p style={{ color: "rgb(51, 73, 159)" }}>+258873750005</p>
              </Box>
            </Box>

            <TextField
              sx={{ display: "block", margin: "2%", color: "red" }}
              size="small"
              fullWidth
              variant="outlined"
              label={t("name")}
              required
              name="name"
            />
            <TextField
              sx={{ display: "block", margin: "2%", color: "red" }}
              size="small"
              fullWidth
              variant="outlined"
              type="email"
              label="email"
              required
              name="email"
            />
            <TextField
              rows={10}
              placeholder={t("message")}
              fullWidth
              multiline
              sx={{ outline: "2px solid #a0c0ff62", margin: "2%" }}
              name="message"
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ margin: "1% 2%", display: "flex", minWidth: "5em" }}
              endIcon={<MdSend />}
              ref={sendbtn}
            >
              {t("send")}
            </Button>
          </Box>
          <Box
            component="a"
            href="https://maps.google.com/maps?q=-25.927032470703125%2C32.55077362060547&z=17&hl=pt-BR "
            target="_blank"
            sx={{
              display: { xs: "none", sm: "grid" },
              width: { xs: "40vw", md: "35vw" },
              background: "#1976D2",
              borderTopRightRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <Box
              component="img"
              src={contactsvg}
              sx={{
                display: { xs: "none", sm: "grid" },
                width: { xs: "40vw", md: "35vw" },
                background: "#1976D2",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
              }}
            />
          </Box>
        </Box>
      </form>
      <Footer />
    </Box>
  );
}

export default Landing;
