import React from "react";
import {
  Grid,
  Box,
  Typography,
  ListItem,
  List,
  IconButton,
  Button,
} from "@mui/material";
import Navbar from "./navbar";
import Footer from "./footer";
import we_are from "./assets/we_are.png";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import { useState } from "react";
import Socials from "./socials";

function Aboutus() {
  // ---styles --------

  const { t } = useTranslation();
  const [language, setLanguage] = useState("po");
  const handleLanguageChange = () => {
    if (language === "en") {
      setLanguage("po");
      i18n.changeLanguage("po");
    } else {
      setLanguage("en");
      i18n.changeLanguage("en");
    }
  };
  window.onscroll = () => {
    if (document.getElementById("socials_box") === null) {
    } else {
      if (window.scrollY > 1200) {
        document.getElementById("socials_box").style.display = "none";
      } else {
        document.getElementById("socials_box").style.display = "grid";
      }
    }
  };
  return (
    <Box>
      <Navbar />
      <Box sx={{ padding: "80px 2%", boxSixing: "border-box" }}>
        <Grid sx={{ marginBottom: "3vh" }}>
          <Typography
            component="h1"
            variant="h5"
            sx={{
              textAlign: "center",
              color: "#193984",
              margin: "3% 4vh 5%",
              fontWeight: "700",
            }}
          >
            {t("wt")}
          </Typography>
          <Button
            sx={{
              position: "fixed",
              padding: "5px 10px",
              top: "12vh",
              right: "0",
              margin: "20px 0%",
              background: "#f4f4f4",
            }}
            onClick={handleLanguageChange}
          >
            {language === "en"
              ? "Translage to Portguese"
              : "Translate to English"}
          </Button>
          <Grid
            container
            spacing={2}
            sx={{ padding: "10px 30px", background: "#fafafa" }}
          >
            <Grid item xs={12} md={4} sx={{ alignItems: "center" }}>
              <img
                src={we_are}
                alt="who we are"
                style={{ width: "100%", height: "50vh" }}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography
                component="p"
                sx={{
                  margin: { xs: "0%", sm: "5% 1%" },
                  textAlign: "justify",
                  fontSize: "larger",
                  fontFamily: "serif",
                  color: "#1a1a1a",
                }}
              >
                {t("who_we_are")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{ position: "fixed", top: "30vh", left: "2%" }}
          id="socials_box"
        >
          <Socials />
        </Box>
        <Grid xs={{ marginBottom: "20px", fontSize: "14px" }}>
          <Typography
            component="h1"
            variant="h5"
            sx={{
              textAlign: "center",
              color: "#193984",
              marginBottom: "40px",
              fontWeight: "700",
            }}
          >
            {t("mt")}
          </Typography>
          <Typography
            component="p"
            sx={{ textAlign: "left", 
            paddingLeft: "10%" , 
            fontSize: 'larger', 
            fontFamily: 'serif'}}
          >
            {t("mission")}
          </Typography>
          <Grid
            sx={{
              lineHeight: "10px",
              fontSize: "14px",
              textAlign: "center",
              padding: "0% 10%",
            }}
          >
            {/* <List>
              
              <ListItem>
                <IconButton>
                  <FcCloseUpMode />
                </IconButton>
                <Typography sx={style3}>{t("m1")}</Typography>
              </ListItem>

              <ListItem>
                <IconButton>
                  <FcCloseUpMode />
                </IconButton>
                <Typography sx={style3}>{t("m2")}</Typography>
              </ListItem>

              <ListItem>
                <IconButton>
                  <FcCloseUpMode />
                </IconButton>
                <Typography sx={style3}>{t("m3")}</Typography>
              </ListItem>

              <ListItem>
                <IconButton>
                  <FcCloseUpMode />
                </IconButton>
                <Typography sx={style3}>{t("m4")}</Typography>
              </ListItem>

              <ListItem>
                <IconButton>
                  <FcCloseUpMode />
                </IconButton>
                <Typography sx={style3}>{t("m5")}</Typography>
              </ListItem>

              <ListItem>
                <IconButton>
                  <FcCloseUpMode />
                </IconButton>
                <Typography sx={style3}>{t("m6")}</Typography>
              </ListItem>

              <ListItem>
                <IconButton>
                  <FcCloseUpMode />
                </IconButton>
                <Typography sx={style3}>{t("m7")}</Typography>
              </ListItem>

              <ListItem>
                <IconButton>
                  <FcCloseUpMode />
                </IconButton>
                <Typography sx={style3}>{t("m8")}</Typography>
              </ListItem>
              
            </List> */}


          </Grid>
        </Grid>

        <Grid sx={{ marginBottom: "20px" }}>
          <Typography
            component="h1"
            variant="h5"
            sx={{
              textAlign: "center",
              color: "#193984",
              margin: "10vh 5vh",
              fontWeight: "700",
            }}
          >
            {t("vt")}
          </Typography>
          <Typography
            component="p"
            sx={{
              margin: "5% 10%",
              textAlign: "justify",
              fontSize: "x-large",
              fontStyle: "italic",
              letterSpacing: "0",
              fontFamily: "serif",
              color: "#001224",
              lineHeight: "1.7em",
            }}
          >
            {t("vision")}
          </Typography>
        </Grid>
      </Box>

      <Footer />
    </Box>
  );
}

export default Aboutus;
