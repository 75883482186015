import React from 'react'
import {Navigate} from 'react-router-dom'
import { UserAuth } from './auth'



const PrivateRoute=({children})=>{
    const {user}=UserAuth()
    if(!user){
        return<Navigate to="/users"/>
    }
    else{
        return children
    
    }
    
}

export default PrivateRoute