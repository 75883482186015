import React from "react";
import { Box, Typography, Grid, Snackbar, Alert, Button } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { MdContentCopy } from "react-icons/md";
import Panel from "./adminNavBar";
import ContentLoader from "react-content-loader";

function Subscriptions() {
  const [open, setopen] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  // ---- funtion to veiw subscptiptons ------

  // _________________________ subscribers api ___________________
  const apiKey =
    "63ea5075c9eb48a5321b5df4-RU3U7DLBJpTsqlTY1q1eskc4Zf1EbynRybnCBSh8f3CVGPQre3";

  const url = `https://api.omnisend.com/v3/contacts?status=subscribed&limit=100`;

  const searchSubs = async () => {
    await fetch(`${url}`, {
      method: "GET",
      headers: {
        accept: "application/json",
        "X-API-KEY":
          "63ea5075c9eb48a5321b5df4-RU3U7DLBJpTsqlTY1q1eskc4Zf1EbynRybnCBSh8f3CVGPQre3",
      },
    })
      .then((res) => res.json())
      .then((data) => {
  
        setSubscribers(data.contacts);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    searchSubs();
  }, []);

 
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return setopen(false);
    }
    setopen(false);
  };

  const MyLoader = () => (
    <ContentLoader>
      <rect x="0" y="0" rx="5" ry="5" width="340" height="70" />
      <rect x="0" y="80" rx="4" ry="4" width="300" height="13" />
      <rect x="0" y="110" rx="3" ry="3" width="250" height="10" />
    </ContentLoader>
  );
  const loading = useRef();

  return (
    <Box>
      <Snackbar
        open={open}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity="info"
          sx={{ width: "100%" }}
        >
          copied to clipboard
        </Alert>
      </Snackbar>
      <Panel />
      <Box
        sx={{
          padding: "2% 3% 10vh",
          borderRadius: "20px",
          boxSizing: "border-box",
          marginLeft: { xs: "3em", md: "15em" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="form"
          sx={{
            marginRight: "1vw",
            padding: "3%",
            display: "grid",
            gridTemplateColumns: "50% 50%",
            background: "snow",
            boxShadow: "2px 2px 30px #e6e3e3",
            borderRadius: "10px",
            marginTop: "10vh",
            boxSizing: "border-box",
            minWidth: "90%",
            marginBottom: "3vh",
          }}
        >
          {subscribers.map(({ email, contactID }) => {
            loading.current.style.display="none"
            return (
              <Typography
                key={contactID}
                variant="p"
                sx={{
                  fontSize: "large",
                  margin: "1%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {email}
              </Typography>
            );
          })}
        </Box>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<MdContentCopy />}
          onClick={() => {
            let emails = "";
            subscribers.forEach((email) => {
              emails = emails + ` ${email.email}\n`;
            });
            navigator.clipboard.writeText(emails);
            setopen(true);
          }}
        >
          copy
        </Button>

        {/* <OmnisendSubscribers/> */}

      </Box>
      <Box
        sx={{
          position: "fixed",
          display: "grid",
          gridTemplateColumns: "20vw 20vw 20vw",
          justifyContent: "space-evenly",
          width: "100%",
          top: "10vh",
          background: "white",
        }}
        ref={loading}
      >
        <MyLoader />
        <MyLoader />
        <MyLoader />
        <MyLoader />
        <MyLoader />
        <MyLoader />
        <MyLoader />
        <MyLoader />
        <MyLoader />
        <MyLoader />
        <MyLoader />
        <MyLoader />
      </Box>
    </Box>
  );
}

export default Subscriptions;
