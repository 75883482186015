import React, { useEffect, useState, useRef } from "react";
import { getDocs, collection, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebase/firebase-config";
import { Button, Typography, Box, Snackbar, Alert } from "@mui/material";
import { FaTrash, FaPenAlt } from "react-icons/fa";
import ContentLoader from "react-content-loader";

function ManageArticles() {
  const [open, setopen] = useState(false);
  const [delId, setdelId] = useState("");
  const [articles, setarticles] = useState([]);
  const articlesref = collection(db, "Articles");

  //getting messages from db

  const getmessages = async () => {
    loading.current.style.display = "grid";
    await getDocs(articlesref).then((data) => {
      const articlesdocs = [];
      data.forEach((item) => {
        articlesdocs.push({ ...item.data(), id: item.id });
        setarticles(articlesdocs);
      });
    });
  };
  useEffect(() => {
    getmessages();
  }, []);

  const MyLoader = () => (
    <ContentLoader>
      <rect x="0" y="0" rx="5" ry="5" width="340" height="70" />
      <rect x="0" y="80" rx="4" ry="4" width="300" height="13" />
      <rect x="0" y="110" rx="3" ry="3" width="250" height="10" />
    </ContentLoader>
  );
  const loading = useRef();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return setopen(false);
    }
    setopen(false);
  };

  //confirming delete
  const confirm = useRef();

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity="error"
          sx={{ width: "100%" }}
        >
          article has been deleted
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: "none",
          position: "fixed",
          background: "#00000047",
          backdropFilter: "blur(2px)",
          width: "100%",
          height: "100vh",
          zIndex: "10",
          top: "0%",
          left: "0%",
        }}
        ref={confirm}
      >
        <Box
          sx={{
            background: "white",
            margin: {
              xs: "30vh 14vw 0%",
              sm: "30vh 25vw 0%",
              md: "30vh 35vw 0%",
            },
            height: "30vh",
            width: "35vw",
            minWidth: "18em",
            padding: "2% 3% 1%",
            borderRadius: "10px",
            boxShadow: "0px 0px 20px grey",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "600" }}>
            Are you sure you want to delete this Article?
          </Typography>
          <p style={{ margin: "2% 0% 2%", fontSize: "larger" }}>
            by clicking yes im confirming that i full understand the
            consequences of my action ,
            <span style={{ color: "red" }}>this action can't be undone</span>
          </p>
          <div style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="error"
              sx={{ fontWeight: "bold" }}
              onClick={(e) => {
                e.target.textContent = "LOADING...";
                deleteDoc(doc(db, "Articles", delId)).then(() => {
                  setopen(true);
                  confirm.current.style.display = "none";
                });
              }}
            >
              YES
            </Button>
            <Button
              variant="contained"
              sx={{
                background: "grey",
                marginLeft: "3%",
                fontWeight: "bold",
                "&:hover": { background: "black" },
              }}
              onClick={(e) => {
                confirm.current.style.display = "none";
              }}
            >
              cancel
            </Button>
          </div>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "10vh 0%",
          margin: "0% auto",
          marginLeft: { xs: "2.5em", md: "8em" },
        }}
      >
        <Box
          sx={{
            marginLeft: { xs: "1em", md: "10em" },
            marginRight: "3vw",
            display: "flex",
            flexWrap: "wrap",

            justifyContent: "space-evenly",
          }}
        >
          {articles.map(({ title, cover, id }) => {
            loading.current.style.display = "none";
            return (
              <Box
                sx={{
                  margin: "5% 1vw",
                  justifyContent: "center",
                  boxShadow: "2px 2px 20px grey",
                  flex: "1 0 21%",
                  maxWidth: "15em",
                  padding: "0% .5% 1%",
                }}
                key={id}
              >
                <Box
                  component="img"
                  src={cover}
                  sx={{ width: "100%", height: "30vh" }}
                />
                <Typography
                  variant="p"
                  sx={{
                    textAlign: "center",
                    padding: "5%",
                    height: "10vh",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                  }}
                >
                  {title}
                </Typography>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    id={id}
                    startIcon={
                      <FaTrash
                        style={{ color: "white" }}
                        onClick={(e) => {
                          confirm.current.style.display = "grid";
                          const articleId =
                            e.target.parentElement.parentElement.parentElement
                              .id;
                          setdelId(articleId);
                          //actually deleting an article
                        }}
                      />
                    }
                    onClick={(e) => {
                      confirm.current.style.display = "grid";
                      const articleId = e.target.id;
                      setdelId(articleId);
                      //actually deleting an article
                    }}
                    color="error"
                    sx={{ margin: "2%", width: "100%" }}
                    variant="contained"
                  >
                    DELETE
                  </Button>
                  <Button
                    id={id}
                    startIcon={
                      <FaPenAlt
                        style={{ color: "white" }}
                        onClick={(e) => {
                          const articleId =
                            e.target.parentElement.parentElement.parentElement
                              .id;
                          window.location.href = `/UpdateBlog?id=${articleId}`;
                        }}
                      />
                    }
                    onClick={(e) => {
                      const articleId = e.target.id;
                      window.location.href = `/UpdateBlog?id=${articleId}`;
                    }}
                    sx={{ margin: "2%", width: "100%" }}
                    variant="contained"
                  >
                    EDIT
                  </Button>
                </div>
              </Box>
            );
          })}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "100%",
          top: "10vh",
          flexWrap: "wrap",
          flex: "1 0 10%",
        }}
        ref={loading}
      >
        <MyLoader />
        <MyLoader />
        <MyLoader />
        <MyLoader />
        <MyLoader />
        <MyLoader />
        <MyLoader />
        <MyLoader />
        <MyLoader />
        <MyLoader />
        <MyLoader />
        <MyLoader />
      </Box>
    </div>
  );
}

export default ManageArticles;
