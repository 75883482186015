import React from 'react'
import {Box, 
    Alert, 
    Snackbar, 
    Typography, 
    TextField, 
    Grid,  Button} from '@mui/material'
import Pannel from './adminNavBar';
import {BiSend} from 'react-icons/bi'
import {useState, useEffect, useRef} from 'react'

import {collection, addDoc, getDocs} from 'firebase/firestore'
import {db} from '../../firebase/firebase-config'
import{ getDownloadURL, getStorage,ref as sRef,uploadBytes }from "firebase/storage"
import ManageTestimonials from './manageTestimonials';


function EnterTestimonials(){
    const [file, setFile] = useState("")
    const sending = useRef();
    const storage = getStorage();
    const testCollection = collection(db, "Testimonials");
    const [success, setSuccess] = useState(false)


    // ---- submit the form -----
    const handleTesti = (e) => {
        const uploaded = () => {
            console.log("wow success")
            sending.current.textContent='Add Testimony'
            setSuccess(true)
            e.target.reset()
        }

        e.preventDefault();
        sending.current.textContent = 'LOADING...';

        if (e.target[7].files[0]){
            console.log('sending a photo')
            const file = e.target[7].files[0];
            const name = file.name;
            const meta = {
                contentType: file.type
            }
            const storageRef = sRef(storage, 'coverImages/'+name)
            uploadBytes(storageRef, file, meta).then((snapshot) =>{
                getDownloadURL(snapshot.ref).then((url) => {
                    console.log(url)
                    addDoc(testCollection, {
                        clientName: e.target[0].value,
                        details: e.target[1].value,
                        email: e.target[3].value,
                        media: url,
                        rating: e.target[4].value,
                        desease: e.target[5].value,
                        link: e.target[6].value,
                        type: 'photo'
                    }).then(uploaded)

                    return;
                })
            }
        )

        }else{
            console.log('sending a video')
            addDoc(testCollection, {
                clientName: e.target[0].value,
                details: e.target[1].value,
                email: e.target[3].value,
                media: e.target[8].value,
                rating: e.target[4].value,
                desease: e.target[5].value,
                link: e.target[6].value,
                type: 'video'
            }).then( uploaded)
        }  
        
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway'){
            return;
            
        }
        setSuccess(false)
        }
    

    return(
        <Box>
        <Pannel/>

        {/* ______snackbar ___________________  */}
        <Snackbar open={success} onClick={handleClose} autoHideDuration={1500} anchorOrigin={{vertical:"top", horizontal: "center"}}>
              <Alert onClose={handleClose} severity="success" variant='filled' sx={{padding: "1% 10px", width: "100%"}}>
                Testimonial was added. Reload the page to if you want to see it in view Testi.
              </Alert>
        </Snackbar>

        <Box sx={{padding: "7vh 0%", width: {xs:'100%', md: '90%'}}}>
            

            <Box sx = {{
                padding:"2% 3% 5vh",
                borderRadius:"20px",
                boxSizing:"border-box",
                marginTop:"5vh",
                paddingLeft:{xs:"5em",md:"15em"},
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                // margin: 'auto'
                overflow: 'hidden'
            }}
            >
            <Typography component="h1" variant="h5" sx={{color:"rgb(1, 73, 144);"}}>ADD TESTIMONIALS</Typography>   
            
            <Grid component="form"
             sx={{mt: 4, width:"90%", pr: 3}} 
             container 
             onSubmit = {handleTesti}
             spacing={3}>
            <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="clientName"
                  label="Client Name"
                  name="clientName"
                  autoFocus
                  variant="filled"
                />
              </Grid>
            <Grid mt={3} item xs={12}>
                <TextField
                  multiline
                  rows={5}
                  required
                  fullWidth
                  id="testimony"
                  label="Tesimony"
                  name="testimony"
                  autoFocus
                  variant="filled"
                />
            </Grid>
            <Grid md={6} sx={12} mt={3} item>
              <TextField
                  required
                  fullWidth
                  type="email"
                  id="email"
                  label="Email"
                  name="email"
                  variant='filled'
                />
            </Grid>
            <Grid md={6} xs={12} sx={{mt: 3}} item>
              <TextField
                  required
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: 5 } }}
                  type="number"
                  id="rating"
                  label="Rating"
                  name="Price"
                  variant='filled'
                />
            </Grid>
            <Grid md={6} sx={12} mt={3} item>
              <TextField
                  required
                  fullWidth
                  type="text"
                  id="disease"
                  label="Desease name or Tesimony title"
                  name="disease"
                  variant='filled'
                />
            </Grid>
            <Grid md={6} xs={12} sx={{mt: 3}} item>
              <TextField
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: 5 } }}
                  type="text"
                  id="link"
                  label="Link (only if available)"
                  name="link"
                  variant='filled'
                />
            </Grid>
            <Grid xs={12} mt={3} item>
            <Typography sx={{textAlign:'center'}}>You may enter either a photo or youtubeVideo id (Enter only one): </Typography>
            </Grid>
            <Grid xs={12} md={6} mt={1} item
            >
            <input type="file" 
                onChange={(e) =>{
                     setFile(URL.createObjectURL(e.target.files[0]))
                }}
                style={{padding:"2%",fontSize:"large",fontWeight:"100"}}/>
                <img src={file} style={{width: "10em"}}/>
            </Grid>

            <Grid xs={12} md={6} item mt={1}>
              <TextField
                  fullWidth
                  type="text"
                  id="url"
                  label="Youtube Video ID"
                  name="video"
                  variant='filled'
                />
            </Grid>


            <hr style={{height:"2px", margin:"10px 0", width:"100%"}}/>
              <Grid item xs={12} sx={{textAlign: "right"}}>
                <Button sx={{background:"rgb(1, 73, 144)", color: "white", "&:hover":{background: "rgb(10, 70, 144)"}}}type="submit" variant='contined' endIcon={<BiSend/>} ref={sending}>Add Testimony</Button>
                <Button variant="contained" color="error"
                onClick={() => {
                    document.forms[0].reset()
                    setFile("")
                }} sx={{ml: 3}}>Cancel</Button>
              </Grid>

            </Grid>
            
            
            </Box>
        </Box>

        <ManageTestimonials/>

        </Box>
        
    )
}

export default EnterTestimonials;