import React, { useState, useRef, useContext } from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import { Typography, Box, Button } from "@mui/material";
import ArticleContext from "./getArticles";
import { BsArrowRight, BsSearch } from "react-icons/bs";
import ContentLoader from "react-content-loader";
import Socials from "./socials";
import not_found from "./assets/not_found.svg";
import { NavLink } from "react-router-dom";

function AboutHealth() {
  const [searchkeyword, setsearchkeyword] = useState("");
  const [searchresult, setsearchresult] = useState(["placeholder"]);

  const articles = useContext(ArticleContext);

  const MyLoader = () => (
    <ContentLoader style={{ margin: "1vh 0vh 5vh" }}>
      <rect x="0" y="0" rx="5" ry="5" width="340" height="150" />
      <rect x="0" y="170" rx="4" ry="4" width="300" height="13" />
      <rect x="0" y="200" rx="3" ry="3" width="250" height="10" />
    </ContentLoader>
  );
  const loading = useRef();

  const blogcard = (title, cover, description, id) => {
    return (
      <Box
        key={id}
        component={NavLink}
        to={`/read-blog?${id}`}
        sx={{
          textDecoration: "none",
          minWidth: "15em",
          maxWidth: "22em",
          margin: "2vh 1%",
          height: { xs: "fit-content", md: "70vh" },
          flex: "1 0 20%",
          boxShadow: "1px 1px 20px #d0cece",
          borderRadius: "20px",
          transition: ".5s",
          "&:hover": {
            scale: { md: "1.02" },
            boxShadow: "1px 1px 20px #9f9d9d",
            background: "#fffcd291",
          },
        }}
      >
        <Box
          component="img"
          src={cover}
          sx={{
            width: "100%",
            height: "200px",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
          }}
        />
        <Box sx={{ padding: "2% 4%" }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              maxHeight: "11.5vh",
              color: "#3e396b",
              lineHeight: "1.2em",
              textOverflow: "clip",
              overflow: "hidden",
              display: "block",
              margin: "1% 0% 4%",
            }}
          >
            {title}
          </Typography>
          <p
            style={{
              maxHeight: "17vh",
              lineHeight: "1.4em",
              textOverflow: "clip",
              overflow: "hidden",
              display: "block",
              marginBottom: "2vh",
              color: "#4f4e4e",
            }}
          >
            {description}
          </p>
        </Box>
        <Box
          sx={{
            display: "flex",
            marginLeft: "4%",
            marginBottom: "4%",
            padding: "0% 2%",
            textDecoration: "none",
            width: "max-content",
            "&:hover": { background: "#eeeaa1" },
          }}
        >
          <p style={{ color: "#ff5454", minWidth: "fit-content" }}>LEIA MAIS</p>
          <BsArrowRight
            style={{
              margin: "0% 1%",
              color: "#ff5454",
              fontSize: "larger",
            }}
          />
        </Box>
      </Box>
    );
  };

  const findblog = () => {
    loading.current.style.display = "grid";
    const allsearch = [];
    searchkeyword.split(" ").forEach((keyword) => {
      if (keyword.length > 3) {
        const foundblogs = articles.filter((item) => {
          const variant1 = keyword.split("");
          const variant2 = keyword.split("");
          variant1.pop();
          variant2.push("s");

          if (
            item.title.toLowerCase().includes(keyword.toLowerCase()) ||
            item.title
              .toLowerCase()
              .includes(variant1.join("").toLowerCase()) ||
            item.title
              .toLowerCase()
              .includes(variant2.join("").toLowerCase()) ||
            item.description.toLowerCase().includes(keyword.toLowerCase()) ||
            item.description
              .toLowerCase()
              .includes(variant1.join("").toLowerCase()) ||
            item.description
              .toLowerCase()
              .includes(variant2.join("").toLowerCase())
          ) {
            allsearch.push(item);
            return item;
          }
        });
      }
    });
    setsearchresult(allsearch);
  };
  const notFound = () => {
    loading.current.style.display = "none";
    return (
      <Box sx={{ display: "grid", justifyContent: "center" }}>
        <Box
          component="img"
          src={not_found}
          style={{ margin: "1% auto", width: { xs: "90%", md: "70%" } }}
        />
      </Box>
    );
  };

  window.onscroll = () => {
    if (document.getElementById("socials_box") === null) {
    } else {
      if (window.scrollY > 400) {
        document.getElementById("socials_box").style.display = "none";
      } else {
        document.getElementById("socials_box").style.display = "grid";
      }
    }
  };
  return (
    <div>
      <Navbar />
      <Box
        sx={{ margin: "0vh 0% 5vh", paddingTop: "14vh", minHeight: "100vh" }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{
            textAlign: "center",
            color: "#193984",
            marginBottom: "0px",
            fontWeight: "700",
          }}
        >
          SOB
          <span style={{ borderBottom: "3px solid", paddingBottom: "1vh" }}>
            RE A SA
          </span>
          ÚDE
        </Typography>
        <Box
          sx={{ position: "fixed", top: "30vh", left: "2%" }}
          id="socials_box"
        >
          <Socials />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            boxSizing: "border-box",
            paddingRight: "1%",
            margin: "3% 0% 2%",
            marginTop: { md: "2vh", xs: "5vh" },
          }}
        >
          <input
            type="text"
            placeholder="search minimum 3 characters"
            autoFocus
            style={{
              padding: "4px 2%",
              fontSize: "large",
              borderRadius: "20px",
              border: "2px solid #0092ce",
              width: "16em",
            }}
            onChange={(e) => {
              setsearchkeyword(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && searchkeyword.length > 3) {
                findblog();
              }
            }}
          />

          <BsSearch
            style={{
              color: "green",
              fontSize: "larger",
              fontWeight: "bold",
              transform: "translate(-28px,7px)",
            }}
            onClick={() => {
              if (searchkeyword.length > 3) {
                findblog();
              }
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            width: "100%",
            background: "white",
            margin: "0%",
          }}
          id="loading"
          ref={loading}
        >
          <MyLoader />
          <MyLoader />
          <MyLoader />
          <MyLoader />
          <MyLoader />
          <MyLoader />
          <MyLoader />
          <MyLoader />
          <MyLoader />
          <MyLoader />
          <MyLoader />
          <MyLoader />
        </Box>
        {/* _________________________________search______________________________________ */}
        <Box
          sx={{
            display: "flex",
            background: "#fcfcfc",
            justifyContent: "center",
            overflowX: { xs: "scroll", sm: "clip" },
            margin: "2% 0% 5%",
          }}
        >
          {searchresult[0] === "placeholder" ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                margin: "2vh auto",
                flexWrap: "wrap",
              }}
            >
              {articles.map(({ title, cover, description, id }) => {
                setTimeout(() => {
                  if (loading)
                    document.getElementById("loading").style.display = "none";
                }, 300);

                return blogcard(title, cover, description, id);
              })}
            </Box>
          ) : searchresult.length === 0 ? (
            notFound()
          ) : (
            <Box
              sx={{
                display: "flex",
                overflowX: { xs: "scroll", md: "clip" },
                flexWrap: { xs: "no-wrap", md: "wrap" },
              }}
            >
              {searchresult.map(({ title, cover, description, id }) => {
                loading.current.style.display = "none";
                if (title === undefined) {
                }
                return blogcard(title, cover, description, id);
              })}
            </Box>
          )}
        </Box>
        {/* _________________________________search______________________________________ */}
      </Box>
      <Box sx={{ display: "grid", justifyContent: "center" }}>
        <Button
          onClick={() => {
            setsearchresult(["placeholder"]);
          }}
        >
          Todos os blogs
        </Button>
      </Box>
      <Footer />
    </div>
  );
}

export default AboutHealth;
