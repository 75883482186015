import React from "react";
import Panel from "./adminNavBar";
import {
  Typography,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Box,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { db } from "../../firebase/firebase-config.js";
import {
  getDocs,
  collection,
  deleteDoc,
  doc,
  addDoc,
} from "firebase/firestore";
import { useState, useEffect, useRef } from "react";
import ContentLoader from "react-content-loader";
import { FaTrash } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { MdOutlineExpandMore } from "react-icons/md";

function AdminConsultation() {
  // -----getting the data from the database ----------
  const [popup, setpopup] = useState({});
  const [open, setopen] = useState(false);
  const [data, setdata] = useState([]);
  const [done, setdone] = useState([]);
  const consCollection = collection(db, "consultationCollection");
  const doneCollection = collection(db, "doneConsultations");
  const doneBox = useRef();
  const viewconsultations = async () => {
    const consultations = [];
    await getDocs(consCollection).then((consultationsall) => {
      consultationsall.docs.forEach((cons) => {
        consultations.push({ ...cons.data(), id: cons.id });
      });
    });
    setdata(consultations);
  };
  const viewDone = async () => {
    const doneConsultations = [];
    await getDocs(doneCollection).then((cons) => {
      cons.docs.forEach((con) => {
        doneConsultations.push({ ...con.data(), id: con.id });
      });
    });
    setdone(doneConsultations);
    console.log(done);
  };
  useEffect(() => {
    viewconsultations();
    viewDone();
  }, [open]);

  const MyLoader = () => (
    <ContentLoader>
      <rect x="0" y="0" rx="5" ry="5" width="340" height="70" />
      <rect x="0" y="80" rx="4" ry="4" width="300" height="13" />
      <rect x="0" y="110" rx="3" ry="3" width="250" height="10" />
    </ContentLoader>
  );
  const loading = useRef();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return setopen(false);
    }
    setopen(false);
  };
  const handleDone = (e) => {
    e.target.textContent = "LOADING...";
    console.log(e.target.id);
    data.filter(async (cons) => {
      if (cons.id === e.target.id) {
        console.log(cons);
        await addDoc(doneCollection, cons).then(async () => {
          await deleteDoc(doc(db, "consultationCollection", e.target.id)).then(
            () => {
              setopen(true);
              setpopup({
                message: "consultation has been saved to done ",
                bg: "success",
              });
            }
          );
        });
      }
      return 0;
    });
  };
  const consCards = (
    theme,
    height,
    lastName,
    age,
    time,
    country,
    details,
    email,
    phone,
    city,
    gender,
    firstName,
    id,
    show
  ) => {
    console.log("mappng");
    return (
      <Card key={id} style={{ backgroundColor: "#fcfcfc", margin: "4% 2%" }}>
        <CardHeader
          avatar={
            <Avatar
              sx={{ bgcolor: "#d5d5d536", padding: "1% 5%" }}
            >
              {firstName.slice(0, 2).toUpperCase()}
            </Avatar>
          }
          title={`${firstName} ${lastName} ,${height}m`}
          subheader={`${gender}, ${age} years old`}
          style={{ backgroundColor: theme }}
        />
        <CardContent>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            style={{ fontSize: "larger", color: "#464646" }}
          >
            <b>City:</b> {city}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            style={{ fontSize: "larger", color: "#464646" }}
          >
            <b>Country:</b> {country}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            style={{ fontSize: "larger", color: "#464646" }}
          >
            <b>Phone:</b> {phone}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            style={{ fontSize: "larger", color: "#464646" }}
          >
            <b>Email:</b> {email}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            style={{ fontSize: "larger", color: "#464646" }}
          >
            <b>Consultation Time:</b> {time}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            style={{ fontSize: "larger", color: "#464646" }}
          >
            <b>Details:</b> {details}
          </Typography>
          <Button
            id={id}
            startIcon={<FaTrash style={{ color: "white" }} />}
            color="error"
            sx={{ margin: "2%", width: "14%", minWidth: "fit-content" }}
            variant="contained"
            onClick={(e) => {
              console.log(e.target.id);
              e.target.textContent = "LOADING...";
              if (show === "none") {
                console.log("none");
                deleteDoc(doc(db, "doneConsultations", e.target.id)).then(
                  () => {
                    setopen(true);
                    setpopup({
                      message: "consultation has been deleted",
                      bg: "error",
                    });
                  }
                );
              } else {
                deleteDoc(doc(db, "consultationCollection", e.target.id)).then(
                  () => {
                    setopen(true);
                    setpopup({
                      message: "consultation has been deleted",
                      bg: "error",
                    });
                  }
                );
              }
            }}
          >
            DELETE
          </Button>
          <Button
            id={id}
            startIcon={<TiTick />}
            sx={{
              background: "green",
              color: "white",
              display: show,
              "&:hover": { background: "lightgreen" },
            }}
            onClick={handleDone}
          >
            Done
          </Button>
        </CardContent>
      </Card>
    );
  };

  return (
    <div>
      <Panel />
      <Snackbar
        open={open}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity={popup.bg}
          sx={{ width: "100%" }}
        >
          {popup.message}
        </Alert>
      </Snackbar>
      <Box sx={{ marginLeft: { xs: "3em", md: "20em" }, paddingTop: "8vh" }}>
        {data.length > 0 || done.length > 0 ? (
          data.map(
            ({
              height,
              lastName,
              age,
              time,
              country,
              details,
              email,
              phone,
              city,
              gender,
              firstName,
              id,
            }) => {
              return consCards(
                "rgb(4, 143, 203)",
                height,
                lastName,
                age,
                time,
                country,
                details,
                email,
                phone,
                city,
                gender,
                firstName,
                id
              );
            }
          )
        ) : (
          <div className="container2">
            <Box
              sx={{
                position: "fixed",
                display: "grid",
                gridTemplateColumns: "20vw 20vw 20vw",
                justifyContent: "space-evenly",
                width: "100%",
                top: "10vh",
                background: "white",
              }}
              ref={loading}
            >
              <MyLoader />
              <MyLoader />
              <MyLoader />
              <MyLoader />
              <MyLoader />
              <MyLoader />
              <MyLoader />
              <MyLoader />
              <MyLoader />
              <MyLoader />
              <MyLoader />
              <MyLoader />
            </Box>
          </div>
        )}
        <Box
          ref={doneBox}
          sx={{
            margin: "10% 2%",
            boxShadow: "0px 0px 30px #80808033",
            padding: "0% 0%",
            borderRadius: "2%",
            height: "7.1vh",
            transition: "1s ease",
            overflow: "hidden",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              border: "solid .5px #80808045",
              padding: "1% 1%",
              borderRadius: "9px",
              color: "#001a42",
              justifyContent: "space-between",
              fontWeight: "bolder",
              background: "#f2f1f1",
            }}
          >
            done consultations
            <Button
              sx={{ margin: "-1% 0%" }}
              startIcon={
                <MdOutlineExpandMore
                  style={{
                    background: "#d2d2d2",
                    fontSize: "xx-large",
                    color: "black",
                    float: "right",
                    borderRadius: "50%",
                  }}
                />
              }
              onClick={(e) => {
                console.log();
                if (doneBox.current.style.height === "fit-content") {
                  doneBox.current.style.height = "8vh";
                } else {
                  doneBox.current.style.height = "fit-content";
                }
              }}
            />
          </Typography>
          {done.map(
            ({
              height,
              lastName,
              age,
              time,
              country,
              details,
              email,
              phone,
              city,
              gender,
              id,
              firstName,
            }) => {
              return consCards(
                "green",
                height,
                lastName,
                age,
                time,
                country,
                details,
                email,
                phone,
                city,
                gender,
                firstName,
                id,
                "none"
              );
            }
          )}
        </Box>
      </Box>
    </div>
  );
}

export default AdminConsultation;
