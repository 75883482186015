import React from "react";
import {
  FaInstagram,
  FaWhatsapp,
  FaFacebookMessenger,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import logo from "./assets/logo.png";
import { db } from "../firebase/firebase-config.js";
import { collection, addDoc } from "firebase/firestore";
import { useRef} from "react";
import i18n from "./i18n";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useState } from "react";
import emailjs from "@emailjs/browser";

 
function Footer({ language }) {

// ---------- sending the email ______________ 
// const sendEmail = (templateParams) => {
//   console.log(templateParams);
//   emailjs
// .send(
//   "service_obvw1nx",
//   "template_42yk0om",
//   templateParams,
//   "MLo95sPsIGhLtqPTd"
// )
// .then(
//   (result) => {
//     console.log(result);
//   },
//   (error) => {
//     console.log(error.text);
//   }
// );

// }

const showPopup =() => {

  // function callMailer() {
  //   setTimeout(() => {
  //     console.log("we are here again")
  //     const button = document.getElementById('omnisend-form-63ea5199ae3d7de16e561f0e-action-627932485028ebd8c6660c51')
  //     button.addEventListener('click', () => {
  //       const email = document.getElementById('omnisend-form-63ea5199ae3d7de16e561f0e-field-container-62a3147776d81914aa87ed98-emailField-input');
  //       email.ref =  'subForm'
  //       if(email.value){

  //         var templateParams = {
  //           email: email.value,
  //           subject: 'Subscription Confirmation'
  //       };
          
  //         sendEmail(templateParams)
  //         console.log(email.value)
  //       }
  //       else{
  //         console.log('no email provided')
  //       }
  //     })
  //   }, 5000)
  // }


  const script = document.createElement("script")
    script.type = "text/javascript";
    script.id = 'omnisend-popup'
    script.innerHTML = 
    `
    window.omnisend = window.omnisend || [];
    omnisend.push(["accountID", "63ea5075c9eb48a5321b5df4"]);
    omnisend.push(["track", "$pageViewed"]);
    !function(){console.log('we are loaded'); var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="https://omnisnippet1.com/inshop/launcher-v2.js";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)}();
    `
    script.onload = function() {
      // Your function code goes here
      console.log('Omnisend popup created!');
    };
    

    document.body.appendChild(script)

}


//  _____ subscribe popup _____ 
useEffect(() => {
  showPopup();
}, []) 


  
  // -----change langueage function -----------
  const { t } = useTranslation();
  useEffect(() => {
    if (language === undefined) {
      i18n.changeLanguage("po");
    }; 
    
  }, []);

  

  const subBtn = useRef();
  const subscriptions = collection(db, "subscriptions");

  const handleSubscribe = async (event) => {
    event.preventDefault();
    // subBtn.current.textContent = "LOADING...";
    // await addDoc(subscriptions, {
    //   email: event.target[0].value,
    // }).then(() => {
    //   event.target.reset();
    //   subBtn.current.textContent = "Subscribe";
    // });
    window.location.reload()
  };

  // const handleSubscribe = async (event) => {
  //   omnisend.push(["accountID", "63ea5075c9eb48a5321b5df4"]);
  //   omnisend.push(["track", "$pageViewed"]);
  //   const e = event;
  //   !function(){var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="https://omnisnippet1.com/inshop/launcher-v2.js";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)}();
  // }

  return (
    <>
    <div>
      <div className="footer" style={{ borderTop: "1px solid #ececec" }}>
        <div className="row1">
          {/* --------- preface --------------------- */}
          <div className="first">
            <img src={logo} alt="logo"/>
            <h3>{t("subtitle")}</h3>
            <p> {t("subtext")}</p>
            {/* -------- subscribe -------------------------- */}

            <form onSubmit={handleSubscribe} id="subscribe"
            style={{marginBottom: "20px"}}
            >
              {/* <input
                type="submit"
                id="subscribe"
                placeholder="email"
                required
                name="email"
                value={t('subscribe')}
              /> */}
              <button type="submit" style={{
                width:"70%",
                marginTop: "10px"
              }} ref={subBtn}>
                {t("subscribe")}
              </button>
            </form>

            <div value={{ className: "socials" }}>
              <a
                href="https://www.youtube.com/@agceramoz"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube style={{ fontSize: "x-large" }} />
              </a>
              <a href="https://wa.link/lz0x3j" target="_blank" rel="noreferrer">
                <FaWhatsapp style={{ fontSize: "x-large" }} />
              </a>
              <a
                href="https://web.facebook.com/AGNUTRITIONMOZAMBIQUE/"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookMessenger style={{ fontSize: "x-large" }} />
              </a>
              <a
                href="https://www.tiktok.com/@agceramoz"
                target="_blank"
                rel="noreferrer"
              >
                <FaTiktok style={{ fontSize: "x-large" }} />
              </a>
              <a
                href="https://www.instagram.com/agceramoz/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram style={{ fontSize: "x-large" }} />
              </a>
            </div>
          </div>

          {/* ------ offices or third -------------- */}
          <div className="office_links">
            <div className="office">
              <h2>{t("office")}</h2>
              <article>
                Mozambique, Maputo city <br />
                Bairro do Jardim, <br />
                1109 Rua das Aleurites, Maputo <br />
                Predio 105, R/C <br />
                Contato: <a href="tel:+258845541504">+258845541504</a>
                <br></br>
                <a href="tel:+258873750005">+258 87 375 0005</a> <br />
                <a href="mailto:info@agceramoz.com">agceramoz@gmail.com</a>
              </article>
            </div>

            {/* -----links   ------ */}
            <div className="links">
              <h2>LINKS</h2>
              <ul>
                <li>
                  <a href="/">{t("home")} </a>
                </li>
                <li>
                  <a href="/consult">{t("consult")}</a>
                </li>

                <li>
                  <a href="/aboutHealth">{t("health")}</a>
                </li>
                <li>
                  <a href="/products">{t("products")}</a>
                </li>
                <li>
                  <a href="/users">#users</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* -----------------last row -------------------- */}
        <div className="copy_right">
          <p>&copy;{t("cpright")}</p>
        </div>
      </div>

     


    </div>
    </>
    
  );

}

export default Footer;
