import React, { useState, useRef, useContext, useEffect } from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import { Typography, Box } from "@mui/material";
import ArticleContext from "./getArticles";
import ContentLoader from "react-content-loader";
import {
  FaShareAlt,
  FaFacebookSquare,
  FaWhatsappSquare,
  FaTwitterSquare,
  FaPinterestSquare,
  FaLinkedin,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";

function SingleBlog() {
  const [single, setsingle] = useState({});
  const [index, setindex] = useState(0);
  const [viewId, setviewid] = useState(
    window.location.toString().split("?")[1]
  );
  const articles = useContext(ArticleContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    articles.filter((item, index) => {
      if (item.id === viewId) {
        setsingle(item);
        setindex(index);
      }

      return 0;
    });
  }, [articles, viewId]);
  console.log(index);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const MyLoader = () => (
    <ContentLoader style={{ margin: "1vh 0vh 5vh" }}>
      <rect x="0" y="0" rx="5" ry="5" width="340" height="150" />
      <rect x="0" y="170" rx="4" ry="4" width="300" height="13" />
      <rect x="0" y="200" rx="3" ry="3" width="250" height="10" />
    </ContentLoader>
  );
  const loading = useRef();

  return (
    <div>
      <Navbar />
      <Box sx={{ height: "15vh", width: "100%" }}></Box>
      <Box>
        <Box
          sx={{
            position: "fixed",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            width: "100%",
            background: "white",
            top: "15vh",
            margin: "0%",
          }}
          ref={loading}
          id="loading"
        >
          <MyLoader />
          <MyLoader />
          <MyLoader />
          <MyLoader />
          <MyLoader />
          <MyLoader />
          <MyLoader />
          <MyLoader />
          <MyLoader />
          <MyLoader />
          <MyLoader />
          <MyLoader />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "column", xs: "row" },
          position: "fixed",
          background: "#fefefe",
          left: { md: "2%", xs: "unset" },
          bottom: "0%",
          transform: { md: "translate(-50%)", xs: "unset" },
          padding: { md: "0px", xs: "0" },
          paddingTop: { sm: "unset", xs: "2px" },
          width: { md: "unset", xs: "100%" },
          boxShadow: {
            sm: "0px 4px 8px rgba(0,0,0,0.3)",
            xs: "7px 0px 8px rgba(0,0,0,0.3)",
          },
          justifyContent: { xs: "center", md: "unset" },
        }}
      >
        <Box
          component="p"
          sx={{
            fontSize: "20px",
            color: "rgb(60, 60, 60)",
            margin: { md: "5px 15px", xs: "10px 5vw 0px" },
            paddingTop: { sm: "2px", xs: "unset" },
          }}
        >
          <FaShareAlt />
        </Box>
        <Box
          component="a"
          href={`https://www.facebook.com/sharer.php?u=${document.location.href}`}
          sx={{
            color: "#1877f2",
            fontSize: "32px",
            margin: { md: "5px 10px", xs: "10px 5vw 0px" },
            transition: ".5s",
            "&:hover": { transform: "scale(1.2)" },
          }}
        >
          <FaFacebookSquare />
        </Box>
        <Box
          component="a"
          target="_blank"
          href={`https://api.whatsapp.com/send?text=${single.title} ${document.location.href}`}
          sx={{
            color: "#075e54",
            fontSize: "32px",
            margin: { md: "5px 10px", xs: "10px 5vw 0px" },
            transition: ".5s",
            "&:hover": { transform: "scale(1.2)" },
          }}
        >
          <FaWhatsappSquare />
        </Box>
        <Box
          component="a"
          target="_blank"
          href={`https://twitter.com/share?url=${document.location.href}&text=${single.title}`}
          sx={{
            color: "#1da1f2",
            fontSize: "32px",
            margin: { md: "5px 10px", xs: "10px 5vw 0px" },
            transition: ".5s",
            "&:hover": { transform: "scale(1.2)" },
          }}
        >
          <FaTwitterSquare />
        </Box>
        <Box
          component="a"
          target="_blank"
          href={`https://pinterest.com/pin/create/bookmarklet/?media=${single.cover}&url=${document.location.href}&description=${single.title}`}
          sx={{
            color: "#e60023",
            fontSize: "32px",
            margin: { md: "5px 10px", xs: "10px 5vw 0px" },
            transition: ".5s",
            "&:hover": { transform: "scale(1.2)" },
          }}
        >
          <FaPinterestSquare />
        </Box>
        <Box
          component="a"
          target="_blank"
          href={`https://www.linkedin.com/shareArticle?url=${document.location.href}&title=${single.title}`}
          sx={{
            color: "#0077b5",
            fontSize: "32px",
            margin: { md: "5px 10px", xs: "10px 5vw 0px" },
            transition: ".5s",
            "&:hover": { transform: "scale(1.2)" },
          }}
        >
          <FaLinkedin />
        </Box>
      </Box>

      <Box sx={{ width: { xs: "95vw", md: "80vw" }, margin: "0% auto" }}>
        <Typography
          variant="h3"
          sx={{
            color: "rgb(60, 60, 60)",
            fontWeight: "bold",
            margin: "1% 0% 1%",
            fontSize: "2.3em",
            fontFamily:
              "Libre Franklin,Freesans,Helmet,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
          }}
        >
          {single.title}
        </Typography>

        <Box
          component="img"
          sx={{
            width: {md: "60%", xs: "100%"},
            margin: "0% auto",
            display: "block",
            maxHeight: "50vh"
          }}
          src={single.cover}
        />

        <Typography
          variant="p"
          sx={{
            fontStyle: "italic",
            fontSize: "1.4em",
            lineHeight: "1em",
            color: "rgba(20, 47, 77, 0.79)",
          }}
        >
          {single.description}
        </Typography>
        <div
          dangerouslySetInnerHTML={{ __html: single.content }}
          id="dangerouslySetInnerHTML"
        />
        <p
          style={{
            color: "rgb(0, 92, 132)",
            fontSize: "large",
            fontStyle: "italic",
            textAlign: "right",
            margin: "1% 0%",
          }}
        >
          {single.date !== undefined
            ? single.date
            : `Monday February ${Math.floor(
                Math.random() * 28 + 1
              )} 2023 16:40:28 GMT+0200 (South Africa Standard Time)`}
        </p>
      </Box>

      <Box
        sx={{
          borderTop: "1px solid grey",
          paddingTop: "10vh",
          display: "flex",
          justifyContent: "space-evenly",
          margin: "3vh auto",
          flexWrap: "wrap",
        }}
      >
        <Typography
          variant="h4"
          sx={{ width: "100vw", margin: "0% 5% 4%", fontWeight: "bold" }}
        >
          Related content
        </Typography>
        {articles.slice(0, 4).map(({ title, cover, description, id }) => {
          setTimeout(() => {
            document.getElementById("loading").style.display = "none";
          }, 300);
          return (
            <Box
              key={id}
              component={NavLink}
              to={`/read-blog?${id}`}
              sx={{
                textDecoration: "none",
                minWidth: "15em",
                maxWidth: "23em",
                margin: "1vh 2vw",
                height: "45vh",
                transition: ".5s",
                "&:hover": {
                  scale: 1.5,
                },
              }}
              onClick={() => {
                setviewid(id);
              }}
            >
              <Box
                component="img"
                src={cover}
                sx={{
                  width: "100%",
                  height: "200px",
                }}
              />
              <Box sx={{ padding: "2% 4%" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    height: "12vh",
                    color: "#3e396b",
                    lineHeight: "1.2em",
                    textOverflow: "clip",
                    overflow: "hidden",
                    display: "block",
                    margin: "1% 0% 4%",
                  }}
                >
                  {title}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  marginLeft: "4%",
                  padding: "2% 2%",
                  textDecoration: "none",
                  width: "max-content",
                  "&:hover": { background: "#eeeab1" },
                }}
              ></Box>
            </Box>
          );
        })}
      </Box>
      <Footer />
    </div>
  );
}

export default SingleBlog;
