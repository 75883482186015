import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          home: 'home',
          consult: "consult",
          health: " health",
          products: "products",
          aboutus: "about us",
          subscribe: "subscribe",
          bcmM: "become a member",

          //____________________main body__________________________//
          hero: "we help you",
          subhero: "get back your health",
          line1:
            "Simple, discreet and convenient. We offer dietary prescriptions for better lifestyle conditions and sexual health. All of our treatments are natural, reliable and effective.",
          btn1: "MEMBER LOGIN",
          btn2: "CONTACT US",
          testi: "imon",
          ials: "als",
          get: "get in touch",
          latest: "Late",
          und: "st arti",
          blog: "cles",
          readMore: "READ MORE",
          search: "search for an article",
                  // --------message ----- 
          name: "Name",
          message: "Type Your message here...",
          send: "SEND",
  

          // ----------------for aboutus ---------------
          wt: 'WHO WE ARE',
          mt: 'OUR MISSION',
          vt: 'OUR VISION',
          who_we_are: " AG CERA NUTRITION MOZAMBIQUE, a leading provider of natural health solutions through food supplements and personalized nutrition counseling (dietary prescription). We believe in the power of nature for healing and empowering individuals to take control of their health. We prioritize safety, effectiveness and customer satisfaction in our services. ",
          mission: "We empower people to achieve optimal health and well-being through natural health solutions, education, personalized service and using revolutionary health supplement products.",
          vision: "AG CERA NUTRITION MOZAMBIQUE vision is to be the leading provider of natural health solutions, helping individuals achieve optimal health and well-being through food supplements and personalized nutrition counseling. Our goal is to empower individuals to take control of their own health and well-being through a holistic approach and education. We strive for natural health solutions to be widely accepted and for our company to be at the forefront of this movement in Our region and internationally.",

          // m1: "Support natural weight loss",
          // m2: "Help lower blood sugar level",
          // m3: "Increase insulin production",
          // m4: "Improve cholesterol and triglyceride level thereby taking care of heart diseases",
          // m5: "Help fight prostate cancer in men and breast cancer in women",
          // m6: "Help treat erectile dysfunctions",
          // m7: "Help fight arthritis and joint pain",
          // m8: "A very powerful anti-aging supplement that makes you look younger and beautifies your skin within few days",

          // ----------for memebership ----------------------
          title1: 'MEMBERSHIP',

          desc1:'AGCERA NUTRITION offers a membership program, for people like you who want to benefit from it in several ways including a lifetime exclusive discounted prices on all our products and more,...... ',
          desc2: 'The company also offers huge opportunities for few qualified and open minded people willing to start a business in health and wellness industry, through our business partnership program. ',
          desc3: 'Below are details and benifits of both membership and business partnership programs:',
       
          ben: 'MEMBERSHIP BENEFITS',
          bn1:'2 boxes of agcera worth 8000Mts ',
          bn2: '22%-28% lifetime discount on all purchases',
          bn3: '45% discount on health checkup ',
          bn4: 'Members card',
          bn5: 'Exclusive health contents',
          bn6: 'distriburorship (resell authorisation)',
          bn7: 'product manual',
          bcm1: 'become a member',
          watch: 'Watch the video to learn more about consultation.',

          // --- for business partners ----
          bsn:'BUSINESS PARTNERSHIP ',
          bsn1: "Looking to start a business in health& wellness industry and have a residual income? AGCERA NUTRITION in partnership with THE BRIGHTMINDS GROUP SA, created a business platform backed by a step by step proven training & mentoship program, for intelligent open minded people who are ready when it comes to new income sources either part time or fulltime. If you are that person, looking for an opportunity, Get started now.",
          bcm2: 'become a business partner',


          // ---------for consult ----------
          cons: 'CONSULT US',
          cons1: 'At AG CERA NUTRITION MOZAMBIQUE, we are dedicated to helping people achieve optimal health and well-being through the use of dietary supplements and personalized nutritional advice.                       With online consultations, you can receive personalized advice and support from the convenience of your own home.          Our team of experts worked with you to create a customized plan to meet your specific health needs and goals.',
          cons2: "Let's dive in and learn how online health consultations with a nutritionist can benefit you! Online health consultations with nutritionists are virtual appointments that occur over the internet. They allow patients to consult healthcare professionals from the convenience of their own homes, through video conferencing, phone calls or messaging platforms.",
          cons3: "During an online consultation with a nutritionist, the patient will usually provide the nutritionist with information about their eating habits, medical history and any health concerns they may have.  Nutritionist will review this information and make personalized recommendations on how to improve the diet and overall health of the patient.",
          cons4: "Online consultations with nutritionists can be helpful for a variety of individuals, including those who wish to improve their overall health, manage a chronic illness or improve their athletic performance. They can also be beneficial for people who have busy schedules or live in remote areas.",
          cons5: "Each person is different. As a result, He/She deserves special care from his/her body. The information here will be accessed by our nutritionists so we can learn a better way to help you stay healthy.",
          cons6: "LOOK BETTER, FEEL BETTER AND LIVE BETTER.",

          ent1: 'Choose your gender: ',
          ent2: "Give some details about your current health status:",
          ent3: "I understand that I will be contacted by AGCera via email or phone.",
          ent4: "Privacy policies are respected and your privacy is highly protected",


          // ____________for footer _______________
          subtitle: "Get our wellness newsletter!",
          subtext: "Filter out the noise and nurture your inbox with health and wellness advice that is inclusive and rooted in medical expertise. No Spam!",
          cpright: "2023 AGCERA Mozambique. All right reserved. Our website services, content, and products are for informational purposes only.",
          office: 'OUR OFFICE',
          // ______________________products _______________ 
          price: "Price",
          learnMore: "Learn More",
          buyNow: "Buy Now",
        
        },
      },
      po: {
        translation: {
          home: 'INÍCIO',
          consult: "CONSULTA",
          health: "SAÚDE",
          products: "PRODUTOS",
          aboutus: "SOBRE NÓS",
          subscribe: "SUBSCREVER",
          bcmM: "SEJA UM DE NÓS",

          //____________________main body__________________________//
          hero: "Ajudamos-lhe",
          subhero: "A recuperar a sua saúde",
          line1:
            "De forma simples, discreta e conveniente. Oferecemos prescrições Dieteticas para Melhores condições de estilo de vida e saúde sexual. Todos os nossos tratamentos são naturais, confiáveis e eficazes",
          btn1: "LOGIN PARA MEMBROS ",
          btn2: "CONTATE-NOS",
          testi: "emun",
          ials: "hos",
          get: "entrar em contato",
          latest: "Artigo",
          und: "s Mais R",
          blog: "ecentes",
          readMore: "Liea Mais",
          search: "insira aqui a sua pesquisa",
                            // ----message---
          name: "Nomes",
          message: "Digite A Sua Mensagem Aqui...",
          send: "ENVIAR",

          // ---------- for aboutus -----------
          wt: 'QUEM SOMOS',
          mt: 'A NOSSA MISSÃO',
          vt: 'A NOSSA VISÃO',
          who_we_are: "AG CERA NUTRITION MOÇAMBIQUE, um fornecedor líder de soluções de saúde natural através de suplementos alimentares e aconselhamento nutricional personalizado (prescrição dietética). Acreditamos no poder da natureza para curar e capacitar os indivíduos para assumirem o controlo da sua saúde. Priorizamos a segurança, eficácia e satisfação do cliente nos nossos serviços.", 
          mission: "A nossa missão é capacitar as pessoas a alcançar a saúde e o bem-estar ideais por meio de soluções de saúde natural, educação nutricional , serviço personalizado e uso de produtos revolucionários de suplementos de saúde.",
          vision: "A visão da AG CERA NUTRITION MOZAMBIQUE é ser o fornecedor líder de soluções naturais de saúde, ajudando os indivíduos a alcançar a saúde e o bem-estar ideais por meio de suplementos alimentares e aconselhamento nutricional personalizado. Nosso objetivo é capacitar os indivíduos a assumir o controle de sua própria saúde e bem-estar por meio de uma abordagem e educação holísticas. Nós nos esforçamos para que as soluções naturais de saúde sejam amplamente aceitas e para que nossa empresa esteja na vanguarda desse movimento em Nossa região e internacionalmente.",
        
          // m1: "Apoiar a perda de peso natural",
          // m2: "Ajudar a baixar o nível de açúcar no sangue",
          // m3: "Aumentar a produção de insulina",
          // m4: "Melhorar o nível de colesterol e triglicéridos, assim, cuidar de doenças cardíacas",
          // m5: "Ajudar a combater o cancro da próstata em homens e cancro da mama nas mulheres",
          // m6: "Ajudar a tratar disfunções erécteis",
          // m7: "Ajude a combater a artrite e a dor nas articulações",
          // m8: "Um poderoso suplemento anti-envelhecimento que o faz parecer mais jovem e embeleza a sua pele dentro de poucos dias",

          
          // for membership--------------------
          title1: 'ADESÃO',
          desc1:'AG CERA NUTRITION MOZAMBIQUE oferece um programa de adesão, para pessoas que querem beneficiar dele de várias maneiras, incluindo um preço exclusivo para toda a vida com desconto em todos os nossos produtos e muito mais,...... ',
          desc2: 'A empresa também oferece enormes oportunidades para poucas pessoas qualificadas e de mente aberta dispostas a iniciar um negócio na indústria da saúde e bem-estar, através do nosso programa de parceria empresarial. ',
          desc3: 'Abaixo estão detalhes e benefícios de programas de adesão e parceria empresarial:',
       
          ben: 'BENEFÍCIOS DE ADESÃO',
          bn1: '2 Caixinhas de Agcera que valem 8000Mts',
          bn2: '22%-28% de desconto vitalício em todas as compras',
          bn3: '45% de desconto no check-up de saúde ',
          bn4: 'Cartão de membros',
          bn5: 'Conteúdos exclusivos para a saúde',
          bn6: 'Distribuição (autorização de revenda)',
          bn7: 'Manual do produto', 
          bcm1: 'Adquira agora',
          watch: 'Veja o vídeo para saber mais sobre a consulta.',
          

          // for business partnership--------------
          bsn: 'PARCERIA COMERCIAL',
          bsn1: 'Procura iniciar um negócio na indústria da saúde e bem-estar e ter um rendimento residual? AG CERA NUTRITION MOZAMBIQUE, em parceria com AG NUTRITION INTERNATIONAL e BRIGHT MINDS GROUP SA, criou uma plataforma de negócio apoiada por um programa de formação e mentoria comprovado passo a passo, para pessoas inteligentes de mente aberta que estão prontas quando se trata de novas fontes de rendimento, quer a tempo parcial quer a tempo inteiro. Se és essa pessoa, à procura de uma oportunidade, começa agora.',
          bcm2: 'tornar-se um parceiro de negócios',


          //  for consult
          cons: 'CONSULTE-NOS',
          cons1: 'Na AG CERA NUTRITION MOZAMBIQUE, estamos dedicados a ajudar as pessoas a alcançar uma ótima saúde e bem-estar através do uso de suplementos alimentares e aconselhamento nutricional personalizado.         Com consultas online, você pode receber conselhos e suporte personalizados do conforto da  própria casa.              Nossa equipe de especialistas trabalhará contigo para criar um plano personalizado para atender às suas necessidades e objetivos de saúde específicos.',
          cons2: 'Vamos mergulhar e aprender como as consultas de saúde online com um nutricionista podem beneficiá-lo!  As consultas de saúde online com nutricionista são compromissos virtuais que ocorrem através da internet. Eles permitem que os pacientes consultem profissionais de saúde do conforto da suas próprias casas, através de videoconferência, telefonemas ou plataformas de mensagens.',
          cons3: "Durante uma consulta online com nutricionista, o paciente geralmente fornecerá ao nutricionista informações sobre seus hábitos alimentares, histórico médico e quaisquer preocupações de saúde que possa ter. Nutricionista revisará essas informações e faram recomendações personalizadas sobre como melhorar a dieta e a saúde geral do paciente.",
          cons4: "As consultas online com nutricionistas podem ser úteis para uma variedade de indivíduos, incluindo aqueles que desejam melhorar sua saúde geral, Controlar uma doença crônica ou melhorar sua performance atlética. Também podem ser benéficos para pessoas que têm agendas ocupadas ou vivem em áreas remotas.",
          cons5: "Cada pessoa é diferente. Como resultado, Ele/ela merece um cuidado especial de seu corpo. As informações aqui serão acessadas por nossos nutricionistas para que possamos saber uma melhor maneira de ajudá-lo a ficar saudável. ",
          cons6: "APARENTE MELHOR, SINTA-SE MELHOR E VIVA MELHOR.",

          ent1: "Escolha o seu sexo: ",
          ent2: "Dê alguns detalhes sobre o seu estado de saúde atual:",
          ent3: "Sei que serei contactado pela AGCera por e-mail ou telefone.",
          ent4:"As políticas de privacidade são respeitadas e a sua privacidade é altamente protegida.",
        
        
          // ________________ footer ____________ 
          subtext: "Filtre o ruído e alimente sua caixa de entrada com conselhos de saúde e bem-estar que são exclusivos e enraizados na experiência nutricional. SEM SPAM!",
          subtitle: "Receba Nosso Newsletter De Bem-estar",
          cpright: "2023 AG Cera Nutrition Mozambique, Todos direitos reservados. O nosso site é apenas para fins informativos dos nossos serviços, produtos e conteúdos sobre saúde.",
          office: 'NOSSO ESCRITÓRIO',
          // ______________________products _______________ 
          price: "Preço",
          learnMore: "Saiba mais",
          buyNow: "Faça o pedido agora",
        },
      },
    },
    lng: "po",
    fallbackLng: "po",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
