import React from 'react'
import Navbar from './navbar'
import Footer from './footer';
import ContentLoader from 'react-content-loader'
import {useEffect, useState,useRef} from 'react'
import {Box, Grid, Typography, Card, CardContent, Rating} from '@mui/material'
import {collection, getDocs} from 'firebase/firestore'
import {db} from '../firebase/firebase-config'

export default function Testimonials() {

    // __________________ get testimonials from the database _______________________
    const testiCollection = collection(db, 'Testimonials')
    const [testi, setTesti] = useState([])

    const getTesti = async() => {
        const testi1 = []
       await getDocs(testiCollection).then((testis) => {
            testis.docs.forEach((testi) => {
                testi1.push({...testi.data(), id:testi.id})
            })
        })
        setTesti(testi1)
        loading.current.style.display="none"
    }

    useEffect(()=>{
        getTesti()
    }, [])
    const MyLoader = () => (
      <ContentLoader style={{margin:"1vh 0vh 5vh"}}>
        <rect x="0" y="0" rx="5" ry="5" width="340" height="150" />
        <rect x="0" y="170" rx="4" ry="4" width="300" height="13" />
        <rect x="0" y="200" rx="3" ry="3" width="250" height="10" />
      </ContentLoader>
    );
    const loading=useRef()




  return (
    <div>
        <Navbar language = 'po'/>
        <Box pt={17} sx={{marginBottom:"10vh"}}>
        <Typography component="h1" variant="h5" 
          sx={{textAlign: "center", 
          color: "#193984", 
          marginBottom:"80px", 
          fontWeight:"700"
          }}>TEST<span style={{borderBottom: "3px solid", paddingBottom: "2vh"}}>EMU</span>NHOS
          </Typography>

          <Box sx={{position:"fixed",display:"flex",flexWrap:"wrap",justifyContent:"space-evenly",width:"100%",background:"white",top:"15vh",margin:"0%"}} ref={loading}>
            <MyLoader /><MyLoader/><MyLoader/>
            <MyLoader /><MyLoader/><MyLoader/>
            <MyLoader /><MyLoader/><MyLoader/>
         </Box>

          <Box>


            {/* ___________ testimonial UI ______   */}



                 {
                    testi.map((test2) => (

                        <Card sx={{width: "100%", marginTop: '20px'}} key={test2.id}>
                    <Grid container  sx={{padding: {md: "30px 60px 20px  10px", xs: "30px 10px"} }}>
                        <Grid item xs={12}  md={4} maxWidth={400}
                        sx={{margin: "auto", marginRight: {md: '30px', xs: 'unset'}}}
                        >
                            {test2.type === 'video' ? (
                                <iframe
                                title="youtube video"
                                style={{ border: "none", borderRadius: '10px'}}
                                width={"95%"}
                                height = '350'
                                src={`https://www.youtube.com/embed/${test2.media}?loop=1`}
                            ></iframe>
                            ):(
                                <img src={test2.media} 
                                alt="Client Photo" 
                                style={{width: '95%', marginTop: '-30px', borderRadius: "4px", maxHeight: '350px'}}/>
                            )} 
                            
                        </Grid>
                        
                        <Grid item xs={12} md={7}>
                        {/* color:"#820000" */}
                        
                        <CardContent>
                        <Typography component='h1' variant='h4' sx={{color:"#193984", fontSize: "20px"}}>{test2.clientName}</Typography>
                        <Typography component='a' variant='p' 
                        href = {`mailto:${test2.email}`}
                        sx={{color:"black", 
                        textDecoration: 'none', display: 'block',
                        fontSize: "15px", 
                        marginBottom: "30px"}}> <strong>Email: </strong>
                            {test2.email}</Typography>  

                            <Typography component='a' variant='h4'
                            target= '_blank'
                            rel = 'noreferrer'
                            href = {(test2.link) ? (test2.link):('#')} 
                            sx={{color:'#820000', 
                            display: 'block',
                            fontSize: '17px', 
                            marginBottom: '10px', 
                            textDecoration:'underline'
                            }}>{test2.desease}
                            </Typography>

                            <Typography component="p" sx={{textAlign: "justify", fontSize: "14px"}}>
                                {test2.details}
                            </Typography>

                            <Rating
                            sx={{
                                margin: "4% 0% 1%",
                                fontSize: { xs: "x-large", md: "xx-large" },
                                display: "flex",
                                justifyContent: "center",
                            }}
                            name="half-rating-read"
                            value= {test2.rating}
                            precision={0.5}
                            readOnly
                            /> 
                            </CardContent>            
                        </Grid>    
                    </Grid>
                 </Card>

                    ))
                 }


            

          </Box>





        </Box>

        <Footer/>

    </div>
  )
}
