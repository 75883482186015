import React from 'react'
import Navbar from './navbar'
import {Box, Grid, Typography, Button, Card, CardContent, CardActions} from '@mui/material'
import {collection, getDocs} from 'firebase/firestore'
import {db} from '../firebase/firebase-config'
import {useEffect, useState,useRef} from 'react'
import ContentLoader from 'react-content-loader'
import Footer from './footer';
import i18n from "./i18n";
import {useTranslation} from 'react-i18next';


function Product(){
  //  ---------translate ----------------
  const {t} = useTranslation()
  const [language, setLanguage] = useState('po')
  const handleLanguageChange = () => {
    if (language === 'en'){
      setLanguage  ('po')
      i18n.changeLanguage("po")
    }
    else{
      setLanguage('en')
      i18n.changeLanguage('en')
    }
  }


    // ----- getting the products from the database -----
    const prodCollection = collection(db, 'prodCollection')
    const [products, setProducts] = useState([])
    const getProducts = async() => {
        const prods = []
       await getDocs(prodCollection).then((products) => {
            products.docs.forEach((product) => {
                prods.push({...product.data(), id:product.id})
            })
        })

        // ______ SORT BY POSITION ____ 
        const sortByPosition = (arr, pos) => {
          return arr.sort((a, b) => a[pos] < b[pos] ? 1 : a[pos] > b[pos] ? -1 : 0)
        };

        sortByPosition(prods, "position")

        setProducts(prods)
        loading.current.style.display="none"

    }

    useEffect(()=>{
        getProducts()
    }, [])
    const MyLoader = () => (
      <ContentLoader style={{margin:"1vh 0vh 5vh"}}>
        <rect x="0" y="0" rx="5" ry="5" width="340" height="150" />
        <rect x="0" y="170" rx="4" ry="4" width="300" height="13" />
        <rect x="0" y="200" rx="3" ry="3" width="250" height="10" />
      </ContentLoader>
    );
    const loading=useRef()



   const ShowProduct = ({prod1}) => {
    return(
      <Grid sx={{marginBottom: "10px"}}>
                <Card sx={{width: "100%"}}>
                <Grid container spacing={6} sx={{padding: {md: "30px 100px", xs: "30px 10px"} }}>
                <Grid item xs={12}  md={4} maxWidth={345}
                sx={{margin: "auto"}}
                >
                    <img src={prod1.photo} 
                    alt="product image" 
                    style={{width: 300, borderRadius: "4px"}}/>
                </Grid>
                
                <Grid item xs={12} md={8}>
                  
                    <CardContent>
                   <Typography component='h1' variant='h4' sx={{color:"#820000", fontSize: "20px", marginBottom: "20px"}}>{prod1.product}</Typography>
                    <Typography component="p" sx={{textAlign: "justify", fontSize: "14px"}}>
                    {prod1.description}
                    </Typography>
                    </CardContent> 

                    <CardActions>
                    
                    <Button component="p" sx={{color: "black"}}><span style={{fontWeight: "bold"}}> {t("price")}:</span> {prod1.price} MTS</Button>
                    <Button 
                    component="a" 
                    href={prod1.learn ? prod1.learn : "https://wa.link/lz0x3j"} 
                    target="_blank"
                    rel="noreferrer"
                    >{t("learnMore")}</Button>  
                    
                    <Button 
                    component="a" 
                    href={prod1.buy ? prod1.buy : "https://wa.link/lz0x3j"} 
                    target="_blank"
                    rel="noreferrer"
                    >{t("buyNow")}</Button>  
                    
                    </CardActions>
                           
                 </Grid>
                 
                 </Grid>
                 </Card>
                 </Grid>

    )
   }
    


    return(

        <Box>
        <Navbar language={language}/>
        <Button
          sx={{
            position: 'fixed',
            padding: "5px 10px",
            top: "12vh",
            right: "0",
            margin: "20px 0%",
            background: "#f4f4f4"
          }}
          onClick={handleLanguageChange}
          >
            {language === 'en' ?
            "Translage to Portguese":
            "Translate to English"}
        </Button>

        <Box pt={17} sx={{marginBottom:"10vh"}}>
          <Typography component="h1" variant="h5" 
          sx={{textAlign: "center", 
          color: "#193984", 
          marginBottom:"80px", 
          fontWeight:"700"
          }}>PR<span style={{borderBottom: "3px solid", paddingBottom: "2vh"}}>ODU</span>TOS
          </Typography>

        <Box sx={{position:"fixed",display:"flex",flexWrap:"wrap",justifyContent:"space-evenly",width:"100%",background:"white",top:"15vh",margin:"0%"}} ref={loading}>
        <MyLoader /><MyLoader/><MyLoader/>
        <MyLoader /><MyLoader/><MyLoader/>
        <MyLoader /><MyLoader/><MyLoader/>
        
        </Box>
            
            {
              products.map((prod1, id) =>
              (
                <ShowProduct key={id} prod1={prod1}/>
              ))
            }
          </Box>
            <Footer/>
        </Box>
    )
}
 
export default Product