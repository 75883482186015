import React from 'react'
import Pannel from './adminNavBar'
import {Box, Alert, Snackbar, Typography, TextField, Grid, Button} from '@mui/material'
import {useState, useEffect, useRef} from 'react'
import {BiSend} from 'react-icons/bi'
import {collection, addDoc, getDocs} from 'firebase/firestore'
import {db} from '../../firebase/firebase-config'
import{ getDownloadURL, getStorage,ref as sRef,uploadBytes }from "firebase/storage"
import ManageProducts from './manageProducts'

function AddProduct(){
    const [file, setFile] = useState("")
    const storage = getStorage();
    const [success, setSuccess] = useState(false)
    const sending = useRef()
    const prodCollection = collection(db, "prodCollection");

 

    const handleProduct = (e) => {
        e.preventDefault();
        sending.current.textContent = 'LOADING...';
        const file = e.target[7].files[0];
        const name = file.name;
        const meta = {
            contentType: file.type
        }
        const storageRef = sRef(storage, 'coverImages/'+name)
        uploadBytes(storageRef, file, meta).then((snapshot) =>{
            getDownloadURL(snapshot.ref).then((url) => {
               console.log(url)
                addDoc(prodCollection, {
                    product: e.target[0].value,
                    description: e.target[1].value,
                    price: e.target[3].value,
                    photo: url,
                    buy: e.target[5].value,
                    learn: e.target[4].value,
                    position: e.target[6].value
                }).then(() => {
                    console.log("wow success")
                    sending.current.textContent='Add Product'
                    setSuccess(true)
                    e.target.reset()
                })
            })
        }
        )

        
        
        // console.log(e.target[6].files[0].name);

    }
    // mapping , celluar communication, tracking, and orientation, communication, how to InputEvent, GSM, at every instant it sends things 
    const handleClose = (event, reason) => {
      if (reason === 'clickaway'){
        return;
        
      }
      setSuccess(false)
    }



    return(
        <Box>
            <Pannel/>
            <Box sx={{padding: "7vh 0%", width: "90%"}}>
            <Snackbar open={success} onClick={handleClose} autoHideDuration={1500} anchorOrigin={{vertical:"top", horizontal: "center"}}>
              <Alert onClose={handleClose} severity="success" variant='filled' sx={{padding: "1% 10px", width: "100%"}}>
                Product was added. Reload the page to if you want to see it in view products.
              </Alert>
            </Snackbar>
            <Box 
            sx={{
            padding:"2% 3% 5vh",
            borderRadius:"20px",
            boxSizing:"border-box",
            marginTop:"5vh",
            marginLeft:{xs:"3em",md:"15em"},
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 'auto'
            }}
            >

             <Typography component="h1" variant="h5" sx={{color:"rgb(1, 73, 144);"}}>ADD PRODUCT</Typography>   

             <Box component="form" sx={{mt: 4, width:"90%", pr: 3}} onSubmit={handleProduct} container>
             <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="productName"
                  label="Product Name"
                  name="productName"
                  autoComplete="ProductName"
                  autoFocus
                  variant="filled"
                />
              </Grid>
             <Grid item xs={12} sx={{mt: 3}}>
                <TextField multiline
                  rows={3}
                  required
                  fullWidth
                  id="description"
                  label="Product Description"
                  name="descripton"
                  autoComplete="description"
                  variant='filled'
                />
              </Grid>
              <Grid xs={12} sx={{mt: 3}} item>
              <TextField
                  required
                  fullWidth
                  type="number"
                  id="price"
                  label="Price per Piece"
                  name="Price"
                  variant='filled'
                />
              </Grid>
              
              <Grid xs={12} sx={{mt: 3}} item>
              <TextField
                  required
                  fullWidth
                  type="text"
                  id="learn"
                  label="Link to Learn More"
                  name="learn"
                  variant='filled'
                />
              </Grid>
              <Grid xs={12} sx={{mt: 3}} item>
              <TextField
                  required
                  fullWidth
                  type="text"
                  id="buy"
                  label="Link to buy Now"
                  name="buy"
                  variant='filled'
                />
              </Grid>
              <Grid xs={12} sx={{mt: 3}} item>
              <TextField
                  required
                  fullWidth
                  type="number"
                  id="position"
                  label="Position"
                  name="position"
                  variant='filled'
                />
              </Grid>
              <Grid item xs={12} sx={{mt: 3, display: "flex", flexDirection: "column"}}>
                <input type="file" 
                onChange={(e) =>{
                     setFile(URL.createObjectURL(e.target.files[0]))
                }} required 
                style={{padding:"2%",fontSize:"large",fontWeight:"100"}}/>
                <img src={file} style={{width: "10em"}}/>
              </Grid>
              <hr style={{height:"2px", margin:"10px 0", width:"100%"}}/>
              <Grid item xs={12} sx={{textAlign: "right"}}>
                <Button sx={{background:"rgb(1, 73, 144)", color: "white", "&:hover":{background: "rgb(10, 70, 144)"}}}type="submit" variant='contined' endIcon={<BiSend/>} ref={sending}>Add Product</Button>
                <Button variant="contained" color="error"
                onClick={() => {
                    document.forms[0].reset()
                    setFile("")
                }} sx={{ml: 3}}>Cancel</Button>
              </Grid>
              

             </Box>

             
            

            </Box>
          
            <ManageProducts sx={{padding: "7vh 0%", width: "90%", paddingLeft: "30vh"}}/>
        
            </Box>

            

        </Box>
    )
}



export default AddProduct


