import React, { createContext, useState, useEffect } from "react";
import { db } from "../firebase/firebase-config.js";
import { collection, getDocs } from "firebase/firestore";

const ArticleContext = createContext();
export const ArticleProvider = ({ children }) => {
  const [articles, setarticles] = useState([]);
  const articlescollRef = collection(db, "Articles");
  useEffect(() => {

    const getArticles = async () => {
      const articlesdocs = [];
      await getDocs(articlescollRef).then((data) => {
        data.forEach((item) => {
          articlesdocs.push({ ...item.data(), id: item.id });
        });
        setarticles(articlesdocs);
      }, []);
    };

    getArticles();
  }, []);

  return (
    <ArticleContext.Provider value={articles}>
      {children}
    </ArticleContext.Provider>
  );
};
export default ArticleContext;
