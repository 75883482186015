import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  FormControlLabel,
  Typography,
  Snackbar,
  Alert,
  Grid,
  Checkbox,
} from "@mui/material";
import { FaUserCircle } from "react-icons/fa";
import Navbar from "./navbar";
import { UserAuth } from "../auth";
import { useNavigate } from "react-router-dom";

function UsersEntryPoint() {
  const [open, setopen] = useState(false);
  const [err, seterr] = useState("");

  const navigate = useNavigate();

  const { signinUser } = UserAuth();

  const handleLogin = async (event) => {
    event.target[5].textContent = "LOADING...";
    seterr("");
    event.preventDefault();
    const { email, password } = event.target.elements;

    try {
      await signinUser(email.value, password.value).then(() => {
        event.target[5].textContent = "WELCOME";
         event.target[5].style.background="green"

        setopen(true);

        navigate("/subs");
      });
    } catch (error) {
       event.target[5].textContent = "LOGIN";
      const custom = error.message.split("auth/")[1];
      seterr(custom);
      setopen(true);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setopen(false);
  };

  return (
    <Box>
      <Snackbar
        open={open}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        {err.length > 2 ? (
          <Alert
            onClose={handleClose}
            variant="filled"
            severity="error"
            sx={{ width: "100%" }}
          >
            {err}
          </Alert>
        ) : (
          <Alert
            onClose={handleClose}
            variant="filled"
            severity="info"
            sx={{ width: "100%" }}
          >
            WELCOME !
          </Alert>
        )}
      </Snackbar>
      <Navbar style={{ top: "0" }} />
      <Box sx={{ padding: "15vh 0%" }}>
        <Box
          sx={{
            padding: "2% 3% 5vh",
            minHeight: "70vh",
            borderRadius: "20px",
            boxSizing: "border-box",
            boxShadow: "2px 2px 20px #00000045",
            width: { xs: "90vw", sm: "25em", md: "30em" },
            margin: "0% auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FaUserCircle
            style={{ fontSize: "10vh", color: "#193984", margin: "1% 0% 2%" }}
          />
          <Typography
            component="h1"
            variant="h5"
            sx={{ color: "rgb(1, 73, 144);" }}
          >
            WELCOME
          </Typography>

          <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, background: "rgb(1, 73, 144);" }}
            >
              Login
            </Button>
            <Grid container>
              <Grid
                item
                xs
                sx={{ display: { xs: "block", md: "inline" } }}
              ></Grid>
              <Grid item></Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default UsersEntryPoint;
