
import React from 'react'
import {FaTrash} from 'react-icons/fa'
import {collection, deleteDoc, getDocs, doc} from 'firebase/firestore'
import{useEffect, useState, useRef} from 'react'
import {db} from '../../firebase/firebase-config'
import {Box, Snackbar, Alert, Grid, Button, Typography, Card, CardMedia, CardActions} from '@mui/material'
import ContentLoader from 'react-content-loader'
function ManageProducts () {
  const [open, setopen] = useState(false);
  const [delId, setdelId] = useState("");
  const [products, setProducts] = useState([])
  const prodCollection = collection(db, "prodCollection");
  const confirm = useRef()
  const loading = useRef()

  const MyLoader = () => (
    <ContentLoader style={{margin: "1vh 0vh 5vh"}}>
      <rect x="0" y="0" ry="5" width="340" height="150"/>
      <rect x="0" y="170" ry="4" width="300" height="13"/>
      <rect x="0" y="200" ry="3" width="250" height="10"/>

    </ContentLoader>
  )
  const getProducts = async() => {
      const prods =[]
      await getDocs(prodCollection).then((prods1) => {
        prods1.docs.forEach((prods2) => {
          prods.push({...prods2.data(), id:prods2.id})
        })
      })
      setProducts(prods)
      loading.current.style.display = "none"

    }  


    useEffect(() => {
      getProducts()
    }, [])


  const AvailableProducts = ({product}) => {

      return (
        <Grid item xs={12} sm={6} md={4} >

                    <Card sx={{maxWidth: 354, position: "relative"}}>
                      <Typography 
                      sx={{position: "absolute",
                      top: "3px",
                      right: "3px",
                      background: "rgb(1, 73, 144)",
                      color: "white",
                      fontWeight: "bolder",
                      padding: "10px",
                      borderRadius: "50%"

                      }}>
                        {product.position}
                      </Typography>
                      <CardMedia component="img" image={product.photo}
                        sx={{
                        height: "170px",
                        boxSizing: "border-box"
                      }}>
                      </CardMedia>

                      <CardActions>
                      <div
                  style={{display: "flex", justifyContent: "space-between"}}
                    >
                      <Typography component="p" 
                      sx={{fontWeight: "bold"}}
                      >{product.product}</Typography>
                  <Button
                    id={product.id}
                    startIcon={
                      <FaTrash
                        style={{ color: "red" , marginLeft: "40px"}}
                        onClick={(e) => {
                          confirm.current.style.display = "grid";
                          const prodId =
                            e.target.parentElement.parentElement.parentElement
                              .id;
                          setdelId(prodId);
                          //actually deleting a product
                         
                        }}
                      />
                    }
                  ></Button>
                </div>
                      </CardActions>

                    </Card>
                  </Grid>
      )
    }

    const handleClose = (event, reason) => {
      if (reason === 'clickaway'){
        return;
      }

      setopen(false);
    }

    return (
      <Box>

        <Snackbar open={open} onClose={handleClose} anchorOrigin={{vertical: "bottom", horizontal: "center"}} autoHideDuration={4000}>
          <Alert severity="error" variant="filled" sx={{width: "100%", padding: "2px 10px"}}>
            Product deleted
          </Alert>

        </Snackbar>
        {/* ---- confiramtion ---- */}
        <Box
        sx={{
          display: "none",
          position: "fixed",
          background: "#00000047",
          backdropFilter: "blur(2px)",
          width: "100%",
          height: "100vh",
          zIndex: "10",
          top: "0%",
          left: "0%",
        }}
        ref={confirm}
      >
        <Box
          sx={{
            background: "white",
            margin: {
              xs: "30vh 7vw 0%",
              sm: "30vh 25vw 0%",
              md: "30vh 35vw 0%",
            },
            height: "30vh",
            width: "35vw",
            minWidth: "18em",
            padding: "2% 3% 1%",
            borderRadius: "10px",
            boxShadow: "0px 0px 20px grey",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "600",
          fontSize: {xs: "18px", md: "20px"}
        }}>
            Are you sure you want to delete this product?
          </Typography>
          <p style={{ margin: "2% 0% 2%", fontSize: "larger" }}>
            by clicking yes im confirming that i full understand the
            consequences of my action ,
            <span style={{color: "red" }}>this action can't be undone</span>
          </p>
          <div style={{textAlign: "right" }}>
            <Button
              variant="contained"
              color="error"
              sx={{fontWeight: "bold" }}
              onClick = {(e) => {
                e.target.textContent = 'LOADING...'
                deleteDoc(doc(db, 'prodCollection', delId)).then(() => {
                  // --- already deleted, reset all features ----
                  e.target.textContent = 'YES'
                  confirm.current.style.display = "none"
                  setopen(true)
                })
              }}
            >
              YES
            </Button>
            <Button
              variant="contained"
              sx={{
                background: "grey",
                marginLeft: "3%",
                fontWeight: "bold",
                "&:hover": { background: "black" },
              }}
              onClick={(e) => {
                confirm.current.style.display = "none";
              }}
            >
              cancel
            </Button>
          </div>
        </Box>
      </Box>


        {/* ------ displaying uploaded products -------- */}

        <Typography component="h3" variant="h4" sx={{paddingTop: "80px",color:"rgb(1, 73, 144)" , fontSize: "20px", fontWeight: "bold", 
                marginBottom: "15px",
                textAlign: "center"}}>Vi<span style={{borderBottom: "3px solid"}}>ew Produ</span>cts</Typography>
               
              <Box
                sx={{
                position: "fixed",
                display: "flex",
                background: "white",
                flexWrap: "wrap",
                margin:"2% 10px",
              }}
              ref= {loading}
              >
                <MyLoader/><MyLoader/><MyLoader/>
                <MyLoader/><MyLoader/><MyLoader/>
                <MyLoader/><MyLoader/><MyLoader/>

              </Box>
               <Grid container sx={{padding: "30px 5% 2% 8%", width: {xs: "100%", md:"80%"}, margin: "auto"}} spacing={3}>
               {
               products.map((product) => (
                 <AvailableProducts product = {product} key={product.id}/>
               ))
             }
                 

               </Grid>

      </Box>
    )

  }  


export default ManageProducts;

