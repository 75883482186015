import React from "react";
import { FaCheckDouble } from "react-icons/fa";
import Navbar from "./navbar";
import Footer from "./footer";
import {
  Grid,
  Box,
  Typography,
  ListItem,
  List,
  IconButton,
  Button,
} from "@mui/material";
import i18n from "./i18n";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

function Membership() {

  // ---- styles ---
  const style2 = {
    fontSize: "1.5em",
    fontFamily: "serif",
    color: "#1a1a1a",
    transition: "1s ease",
  };
  const style1 = { fontSize: "large", color: "green" };

  const desc_style = {
    textAlign: "left",
    fontSize: "larger",
    padding: "0 2%",
    fontFamily: "serif",
    color: "#1a1a1a",
    marginBottom: "5vh",
  };

  const { t } = useTranslation();
  const [language, setLanguage] = useState("po");
  const handleLanguageChange = () => {
    if (language === "en") {
      setLanguage("po");
      i18n.changeLanguage("po");
    } else {
      setLanguage("en");
      i18n.changeLanguage("en");
    }
  };

  return (
    <div>
      <Navbar language={language} />

      <h1
        className="title"
        style={{
          padding: "15vh 0 7vh 0",
          textAlign: "center",
          color: "rgb(61, 61, 160)",
        }}
      >
        {t("title1")}
      </h1>

      <Button
        sx={{
          position: "fixed",
          padding: "5px 10px",
          top: "12vh",
          right: "0",
          margin: "20px 0%",
          background: "#f4f4f4",
        }}
        onClick={handleLanguageChange}
      >
        {language === "en" ? "Translage to Portguese" : "Translate to English"}
      </Button>

      <Grid
        container
        maxWidth={{ xs: "90vw", md: "unset" }}
        sx={{
          marginLeft: { md: "unset", xs: "3%" },
          padding: { xs: "0 2%", md: "0 15%" },
          lineHeight: "28px",
        }}
      >
        <Typography component="p" sx={desc_style}>
          {t("desc1")}
        </Typography>
        <Typography component="p" sx={desc_style}>
          {t("desc2")}
        </Typography>
        <Typography component="p" sx={desc_style}>
          {t("desc3")}
        </Typography>
      </Grid>

      {/* -- benefites ---- */}

      {/*------ for the member --------------- */}
      <Grid
        container
        mt={5}
        maxWidth={"100vw"}
        sx={{ padding: { md: "unset", xs: "0 2%" } }}
      >
        <Grid
          item
          sm={12}
          md={6}
          sx={{
            border: "1px solid rgb(211, 211, 211)",
            borderRadius: "7px",
            boxShadow: "3px 2px 2px rgb(218, 218, 221)",
            padding: { sm: "20px 10px", xs: "20px 0" },
            fontFamily: "serif",
            position: "relative",
            margin: "auto",
          }}
        >

          <Typography
            component="h3"
            sx={{
              fontSize: "x-large",
              fontWeight: "bold",
              textAlign: "center",
              color: "rgb(61, 61, 160)",
            }}
          >
            {t("ben")}
          </Typography>
          <div className="popular">mais populares</div>
          <div className="cost">7500 MTS</div>

          <List>
            <ListItem>
              <IconButton sx={style1}>
                <FaCheckDouble />
              </IconButton>
              <Typography sx={style2}>{t("bn1")}</Typography>
            </ListItem>

            <ListItem>
              <IconButton sx={style1}>
                <FaCheckDouble />
              </IconButton>
              <Typography sx={style2}>{t("bn2")}</Typography>
            </ListItem>

            <ListItem>
              <IconButton sx={style1}>
                <FaCheckDouble />
              </IconButton>
              <Typography sx={style2}>{t("bn3")}</Typography>
            </ListItem>

            <ListItem>
              <IconButton sx={style1}>
                <FaCheckDouble />
              </IconButton>
              <Typography sx={style2}>{t("bn4")}</Typography>
            </ListItem>

            <ListItem>
              <IconButton sx={style1}>
                <FaCheckDouble />
              </IconButton>
              <Typography sx={style2}>{t("bn5")}</Typography>
            </ListItem>

            <ListItem>
              <IconButton sx={style1}>
                <FaCheckDouble />
              </IconButton>
              <Typography sx={style2}>{t("bn6")}</Typography>
            </ListItem>

            <ListItem>
              <IconButton sx={style1}>
                <FaCheckDouble />
              </IconButton>
              <Typography sx={style2}>{t("bn7")}</Typography>
            </ListItem>
          </List>

          <Box sx={{ textAlign: "center" }}>
            <Button
              component="a"
              href="https://wa.link/814wte"
              rel="noreferrer"
              target="_blank"
              sx={{
                background: "rgb(236, 76, 18)",
                color: "white",
                fontSize: "larger",
                textTransform: "capitalize",
                padding: "1.5% 5vw",
                "&:hover": {
                  background: "rgb(236, 76, 18)",
                  opacity: "0.7",
                },
              }}
            >
              {t("bcm1")}
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container mt={11}>
        <Typography
          component="h3"
          variant="h3"
          mb={4}
          sx={{
            fontSize: { md: "xx-large", xs: "x-large" },
            width: "100%",
            textAlign: "center",
            color: "rgb(61, 61, 160)",
            textAlign: "center",
          }}
        >
          {t("bsn")}
        </Typography>

        <Typography
          component="p"
          mb={20}
          maxWidth={{ xs: "90vw", md: "unset" }}
          sx={{
            margin: { xs: "0 0 10vh 5vw", sm: "2vh 2% 20vh 2%" },
            textAlign: { xs: "justufy", md: "unset" },
            display: "block",
            fontSize: "larger",
            fontFamily: "serif",
            color: "#1a1a1a",
            padding: { xs: "unset", md: "0 17%" },
          }}
        >
          {t("bsn1")}
        </Typography>

        <Box sx={{ textAlign: "center", width: "100%" }}>
          <Button
            component="a"
            href="https://wa.link/7mdqt8"
            rel="noreferrer"
            target="_blank"
            sx={{
              background: "rgb(61, 61, 160)",
              color: "white",
              fontSize: "larger",
              margin: "-5vh 0 10vh 0",
              textTransform: "capitalize",
              padding: "1% 4vw",
              "&:hover": { background: "rgb(61, 61, 160)", opacity: "0.7" },
            }}
          >
            {t("bcm2")}
          </Button>
        </Box>
      </Grid>

      <Footer />
    </div>
  );
}

export default Membership;
