import React, { createContext, useState, useEffect } from "react";
import { db } from "../../firebase/firebase-config.js";
import { collection, getDocs } from "firebase/firestore";

const MessageContext = createContext();
export const MessageProvider = ({ children }) => {
  const [messages, setmessages] = useState([]);
  useEffect(() => {
    const viewallMessages = async () => {
      const consCollection = collection(db, "messages");
      const allMessages = [];
      await getDocs(consCollection).then((messages) => {
        messages.docs.forEach((message) => {
          allMessages.push({ ...message.data(), id: message.id });
        });
      });
      setmessages(allMessages)
    };
    viewallMessages();
  }, []);
  return (
    <MessageContext.Provider value={messages}>
      {children}
    </MessageContext.Provider>
  );
};
export default MessageContext;
