import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Membership from "./components/membership";
import Landing from "./components/landing";
import Consult from "./components/consult";
import UsersEntryPoint from "./components/usersEntry";
import Addmember from "./components/business/addmember";
import PrivateRoute from "./privateRoutes";
import { AuthContextProvider } from "./auth";
import Subscriptions from "./components/business/subscriptions";
import Addblog from "./components/business/addArticles";
import ManageProducts from "./components/business/manageProducts";
import AddProduct from "./components/business/addProduct";
import Products from "./components/products";
import AboutHealth from "./components/aboutHealth";
import SingleBlog from "./components/singleBlog";
import Aboutus from "./components/aboutus";
import AdminConsultation from "./components/business/adminConsultation";
import { ArticleProvider } from "../src/components/getArticles";
import { MessageProvider } from "../src/components/business/getmessages";
import UpdateBlog from "./components/business/updateBlog";
import SliderViews from "./components/slider";
import EnterTestimonials from "./components/business/EnterTestimonials";
import Testimonials from "./components/testimonials";
import UpdateProduct from "./components/business/updateBlog";

function App() {
  return (
    <>
      <Router>
        <AuthContextProvider>
          <Routes>
        <Route path="/slider" element={<SliderViews />} />
            <Route path="/*" element={<Landing />} />
            <Route
              exact
              path="/"
              element={
                <ArticleProvider>
                  <Landing />
                </ArticleProvider>
              }
            />
            <Route exact path="/membership" element={<Membership />} />

            <Route exact path="/testimonials" element={<Testimonials />} />
            <Route exact path="/produpdate" element={<UpdateProduct />} />
           
            <Route path="/consult" element={<Consult />} />
            <Route
              path="/aboutHealth"
              element={
                <ArticleProvider>
                  <AboutHealth />
                </ArticleProvider>
              }
            />
            <Route path="/products" element={<Products />} />
            <Route
              path="/read-blog"
              element={
                <ArticleProvider>
                  <SingleBlog />
                </ArticleProvider>
              }
            />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/managep" element={<ManageProducts />} />
            <Route exact path="/users" element={<UsersEntryPoint />} />

            <Route
              exact
              path="/subs"
              element={
                <PrivateRoute>
                  <MessageProvider>
                    <Subscriptions />
                  </MessageProvider>
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/addTesti"
              element={
                <PrivateRoute>
                  <MessageProvider>
                    <EnterTestimonials />
                  </MessageProvider>
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/employee"
              element={
                <PrivateRoute>
                  <Addmember />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/addblog"
              element={
                <PrivateRoute>
                  <MessageProvider>
                    <ArticleProvider>
                      <Addblog />
                    </ArticleProvider>
                  </MessageProvider>
                </PrivateRoute>
              }
            />

            <Route
              exact
              path="/addProduct"
              element={
                <PrivateRoute>
                  <MessageProvider>
                    <AddProduct />
                  </MessageProvider>
                </PrivateRoute>
              }
            />
            
            <Route
              exact
              path="/Adminconsultation"
              element={
                <PrivateRoute>
                  <MessageProvider>
                    <AdminConsultation />
                  </MessageProvider>
                </PrivateRoute>
              }
            />

            <Route
              exact
              path="/updateBlog"
              element={
                <PrivateRoute>
                  <MessageProvider>
                    <ArticleProvider>
                      <UpdateBlog />
                    </ArticleProvider>
                  </MessageProvider>
                </PrivateRoute>
              }
            />
          </Routes>
        </AuthContextProvider>
      </Router>
    </>
  );
}

export default App;
